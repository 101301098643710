import React, { useEffect, useState } from 'react'
import './style.css';
import { useNavigate, useParams } from 'react-router-dom';
import useCart from '../../globals/useCart';
import Button from '../Button';
import Api from '../../Api';

export default ({activeTab, activeColor}) => {

    const navigate = useNavigate();
    
    const {keybind} = useParams();
    const { cartPrice } = useCart(keybind);
    const [cartCount, setCartCount] = useState(0);
    const [mesaActivity, setMesaActivity] = useState(localStorage.getItem("CARDAPPYO_CURRENT_MESA") != null);

    const [bottomMenu, setBottomMenu] = useState();
    const [pessoaTitle, setPessoaTitle] = useState("Pessoas(0)");
    const [pessoas, setPessoas] = useState([]);

    useEffect(() => {
        setBottomMenu([
            {
                id: 0,
                icon: <i className="fa fa-cutlery"></i>,
                title: "Cardápio",
                to: (mesaActivity) ? `/${localStorage.getItem("CARDAPPYO_CURRENT_FILIAL")}/${localStorage.getItem("CARDAPPYO_CURRENT_KEYBIND")}/${localStorage.getItem("CARDAPPYO_CURRENT_MESA")}` : `/${localStorage.getItem("CARDAPPYO_CURRENT_FILIAL")}/${localStorage.getItem("CARDAPPYO_CURRENT_KEYBIND")}`,
                active: activeTab == 0,
                badgeValue: false
            },
            {
                id: 1,
                icon: (mesaActivity) ? <i class="fa fa-users"></i> : <i class="fa fa-map-marker"></i>,
                title: (mesaActivity) ? "Pessoas" : "Endereços",
                to: (mesaActivity) ? "/pessoas" : "/enderecos",
                active: activeTab == 1,
                badgeValue: false
            },
            {
                id: 2,
                icon: <i className="fa fa-list-alt"></i>,
                title: (mesaActivity) ? "Comanda" : "Pedidos",
                to: (mesaActivity) ? "/comanda" : "/pedidos",
                active: activeTab == 2,
                badgeValue: false
            },
            {
                id: 3,
                icon: <i className="fa fa-shopping-basket"></i>,
                title: "Sacola",
                to: (mesaActivity) ? "/cart-mesa" : "/cart",
                active: activeTab == 3,
                badgeValue: true
            },
        ])
    }, [mesaActivity])

    useEffect(() => {
        setMesaActivity(localStorage.getItem("CARDAPPYO_CURRENT_MESA") != null);
        setCartCount(getMaxCountItems());
    })

    useEffect(() => {
        const fetchPessoasMesa = async () => {
            let responsePessoas = await Api.getPessoaMesa({
                id_mesa: localStorage.getItem("CARDAPPYO_CURRENT_MESA"),
            })
            setPessoas(responsePessoas?.data?.data)
        }
        fetchPessoasMesa();
    }, [])

    const handleButtonClick = (m) => {
        navigate(m.to);
    }

    const getCartFromLocalStorage = () => {
        const cart = localStorage.getItem('CARDAPPYO-CART-'+keybind);
        return cart ? JSON.parse(cart) : [];
    };

    const getMaxCountItems = () => {
        let cart = getCartFromLocalStorage();
        let count = 0;
        for(let i = 0; i < cart.length; i++){
          count += cart[i].quantity;
        }
        return count;
    }

    const Nav = () => (
        <div className='bottom-menu'>
            {bottomMenu?.map((m, i) => (
                <div key={i} className={`bottom-menu-item`} style={{color: activeColor ? (m.active) ? activeColor : '' : ''}}>
                    <div onClick={() => {handleButtonClick(m)}}>
                        <div className='bottom-menu-icon'>
                            {m.badgeValue && cartCount > 0 ? (
                                <div style={{position: 'absolute', marginLeft: '25px', marginTop: '-5px', display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'red', borderRadius: '50%', color: 'white', fontSize: '8pt', width: '15px', height: '15px'}}><b>{cartCount}</b></div>
                            ) : (null)}
                            {(m.icon)}
                        </div>
                        <div className='text-center'>
                            <b className='bottom-menu-b'>{m.title}</b>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )

    const GoCheckout = () => (
        <>
            {cartCount > 0 ? (
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'fixed', bottom: '70px', zIndex: '99999', width: '98%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: activeColor, borderRadius: '8px', paddingTop: '8px', paddingBottom: '8px'}}>
                        <div style={{marginLeft: '10px'}}>
                            <b style={{color: 'white'}}><i style={{color: 'white'}} className="fa fa-shopping-basket"></i>({cartCount}) {cartPrice.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                        </div>
                        <div style={{marginRight: '10px'}}>
                            <Button background={"#ffffff"} onClick={() => {navigate(mesaActivity ? "/cart-mesa" : "/cart")}}>
                                <div>
                                    <i style={{color: activeColor}} className="fa fa-shopping-basket"></i>&nbsp;
                                    <b style={{color: activeColor}}>Pedir</b>
                                </div>
                            </Button>
                        </div>
                    </div>
                    </div>
            ) : (null)}
        </>
    )

    return (
        <>
            <GoCheckout/>
            <Nav/>
        </>
    )
}
