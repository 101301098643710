import React from 'react';
import "./style.css";

export default ({children, background, onClick, width, outline}) => {
  return (
    <>
      {outline ? (
        <button className='button' onClick={onClick} style={{display: 'flex', alignItems: 'center', width: width ? width : 'auto', background: '#fff', border: `solid 1px ${background}`, color: background, paddingTop: '10px', cursor: 'pointer', paddingBottom: '10px', paddingLeft: '15px', paddingRight: '15px', borderRadius: '8px', fontSize: '12pt'}}>
          {children}
        </button>
        ) : (
        <button className='button' onClick={onClick} style={{display: 'flex', alignItems: 'center', width: width ? width : 'auto', background, color: (background) ? 'white' : 'black', paddingTop: '10px', cursor: 'pointer', paddingBottom: '10px', paddingLeft: '15px', paddingRight: '15px', borderRadius: '8px', fontSize: '12pt'}}>
          {children}
        </button>
      )}
    </>
  )
}
