import React, { useEffect, useState } from 'react';
import "./style.css";
import {BottomNavigation, Button, Header, Hr, Modal, PedidoItem} from '../../components';
import Api from '../../Api';
import { useNavigate } from 'react-router-dom';
import Utils from '../../Utils';
import { ToastContainer } from 'react-toastify';

export default () => {

  const navigate = useNavigate();

  const [ready, setReady] = useState(false);
  const [cardappyo, setCardappyo] = useState(null);
  const [empresa, setEmpresa] = useState(null);
  const [franquia, setFranquia] = useState(null);
  const [pedidos, setPedidos] = useState([]);
  const [itens, setItens] = useState([]);
  const [mesa, setMesa] = useState(null);
  const [modalOpenFecharComanda, setModalOpenFecharComanda] = useState(false);
  const [pedidosIds, setPedidosIds] = useState([]);
  const [comanda, setComanda] = useState(null);

  const cardappyoUrl = "/"+localStorage.getItem("CARDAPPYO_CURRENT_FILIAL")+"/"+localStorage.getItem("CARDAPPYO_CURRENT_KEYBIND")+(localStorage.getItem("CARDAPPYO_CURRENT_MESA") != null ? ("/"+localStorage.getItem("CARDAPPYO_CURRENT_MESA")) : "");

  useEffect(() => {
    const fetchCardappyo = async () => {
      setReady(false);
      let responseCardappyo = await Api.getCardappyo({keybind: localStorage.getItem("CARDAPPYO_CURRENT_KEYBIND")});
      setCardappyo(responseCardappyo?.data?.data);
      let responseEmpresa = await Api.getEmpresa({id_empresa: responseCardappyo?.data?.data?.id_empresa});
      setEmpresa(responseEmpresa?.data?.data);
      let responseFilial = await Api.getFilial({id_empresa: responseCardappyo?.data?.data?.id_empresa, id_filial: localStorage.getItem("CARDAPPYO_CURRENT_FILIAL")});
      setFranquia(responseFilial?.data?.data);
      let responseMesa = await Api.getMesa({id_cardapio: responseCardappyo?.data?.data?.id_cardapio, id_empresa: responseEmpresa?.data?.data?.id_empresa, id_filial: responseFilial?.data?.data?.id_filial, id_mesa: localStorage.getItem("CARDAPPYO_CURRENT_MESA")})
      setMesa(responseMesa?.data?.data[0]);
      let responsePedidos = await Api.getPedidos({id_cardapio: responseCardappyo?.data?.data?.id_cardapio, id_empresa: responseCardappyo?.data?.data?.id_empresa, id_filial: localStorage.getItem("CARDAPPYO_CURRENT_FILIAL"), id_mesa: localStorage.getItem("CARDAPPYO_CURRENT_MESA")});
      setPedidos(responsePedidos?.data?.data);
      let ids_pedidos = [];
      for(let i = 0; i < responsePedidos?.data?.data?.length; i++){
        let pedido = responsePedidos?.data?.data[i];
        ids_pedidos.push(pedido?.id_pedido);
      }
      setPedidosIds(ids_pedidos);
      let responseItens = await Api.getComandaItens({ids_pedidos: ids_pedidos});
      setItens(responseItens?.data?.data);
      fetchComanda();
      setReady(true);
    }
    fetchCardappyo();
  }, [])

  const fetchComanda = async () => {
    let responseComanda = await Api.getComanda({id_mesa: localStorage.getItem("CARDAPPYO_CURRENT_MESA")});
    if(responseComanda?.data?.success){
      setComanda(responseComanda?.data?.data);
    }
  }

  const getItemPreco = (item) => {
    let preco = 0;
    preco = Number(item.preco) * Number(item.quantidate);

    let preco_adicionais = 0;
    let adicionais = JSON.parse(item.adicionais);
    for(let i = 0; i < adicionais.length; i++){
      preco_adicionais += Number(adicionais[i].preco) * Number(adicionais[i].quantity);
    }

    preco_adicionais = preco_adicionais * item.quantidate;

    return Number(preco+preco_adicionais);
  }

  const getTotal = () => {
    let total = 0;
    for(let i = 0; i < itens?.length; i++){
      total += getItemPreco(itens[i]);
    }
    return Number(total);
  }

  const getTaxa = () => {
    return Number((getTotal()/100)*franquia?.taxa_garcom);
  }

  const closeModalOpenFecharComanda = () => {
    setModalOpenFecharComanda(false);
  }

  const fecharComanda = () => {
    setModalOpenFecharComanda(true);
  }

  const fecharComandaOk = async () => {

    let comanda = {
      id_mesa: localStorage.getItem("CARDAPPYO_CURRENT_MESA"),
      ids_pedidos: pedidosIds,
      itens: itens,
      nome_cliente: localStorage.getItem("CARDAPPYO_CURRENT_USERNAME"),
      subtotal: getTotal(),
      taxa: getTaxa(),
      total: (getTotal()+getTaxa()),
    }
    
    let responsePedidos = await Api.getPedidos({id_cardapio: cardappyo?.id_cardapio, id_empresa: cardappyo?.id_empresa, id_filial: localStorage.getItem("CARDAPPYO_CURRENT_FILIAL"), id_mesa: localStorage.getItem("CARDAPPYO_CURRENT_MESA")});
    if(!responsePedidos?.data?.success){
      Utils.notify("error", "Essa comanda já está fechada!");
      setTimeout(() => {
        window.location.reload();
      }, 1500)
      return;
    }

    let response = await Api.createComanda({comanda: comanda});

    await fetchComanda();

    if(response?.data?.success){
      Utils.notify("success", "Comanda fechada!");
      setModalOpenFecharComanda(false);
    }else{
      Utils.notify("error", response?.data?.message);
      setTimeout(() => {
        window.location.reload();
      }, 2500)
    }

  }

  return (
    <>
      <ToastContainer style={{zIndex: '999999999'}}/>
      <Modal 
        open={modalOpenFecharComanda}
        onClose={closeModalOpenFecharComanda}
        title={`Já vai? tá cedo.`}
        buttonConfirmBackground={empresa?.cor_primaria}
        buttonCancelText={"Voltar"}
        buttonConfirmText={"Confirmar"}
        onConfirm={fecharComandaOk}
        buttonConfirmIcon={<i></i>}
        >
          <div style={{background: "#f6eccd", color: '#6b5509', border: 'solid 1px #6b5509', borderRadius: '4px', padding: '4px'}}><i class="fa fa-info-circle"></i>&nbsp;<b style={{textDecoration: 'underline'}}>ATENÇÃO</b>&nbsp;Só feche a comanda quando for embora do estabelecimento.</div>
          <div style={{border: 'solid 1px #f5f5f5', padding: '8px', borderRadius: '4px', marginTop: '20px'}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <b>Subtotal:</b>
              <b>{getTotal().toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <b>Taxa (opcional):</b>
              <b>+({franquia?.taxa_garcom}%) {getTaxa().toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <b>Total:</b>
              <b>{(getTotal()+getTaxa()).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
            </div>
          </div>
      </Modal>
      <Header goBack={true} title={`Comanda da ${mesa?.nome}`} cardappyo={cardappyo} franquia={franquia} empresa={empresa}/>
      <div style={{paddingLeft: '16px', paddingRight: '16px', display: ready ? "block" : "none" }}>
        {pedidos == undefined ? (
          <>
            <h4 style={{color: '#4b4b4b', marginTop: '20px', marginBottom: '10px'}}>Não há pedidos!</h4>
          </>
        ) : (
          itens.length > 0 ? (
            <>
              <h4 style={{color: '#4b4b4b', marginTop: '20px', marginBottom: '10px'}}>Comanda da {mesa?.nome} | ({itens?.length}) itens</h4>
              <div className='container-card-comanda'>

                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{marginRight: '10px'}}>
                      <img src={empresa?.logo_pequena} style={{width: '50px', height: '50px', objectFit: 'cover', borderRadius: '50%'}} />
                  </div>
                  <div>
                      <h5 style={{color: '#4b4b4b'}}>{franquia?.nome_fantasia}</h5>
                  </div>
                </div>

                <div style={{overflowY: 'auto', maxHeight: 'calc(100vh - 520px)'}}>
                  <table>
                    <thead>
                      <tr>
                        <th>Qtd</th>
                        <th>Item</th>
                        <th>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {itens?.map((item, index) => (
                        <tr key={index} style={{marginBottom: '10px', marginTop: '0px', background: "#f9f9f9"}}>
                          <td>{item.quantidate}</td>
                          <td>{item.nome}</td>
                          <td>{getItemPreco(item).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div style={{position: 'absolute', bottom: 'calc(0px + 95px)', width: 'calc(100% - 50px)'}}>

                  <div style={{border: 'solid 1px #f5f5f5', background: '#fff', padding: '8px', borderRadius: '4px', marginTop: '20px'}}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                      <b>Identificação:</b>
                      <b>{pedidos[0]?.nome}</b>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                      <b>Subtotal:</b>
                      <b>{getTotal().toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                      <b>Taxa:</b>
                      <b>+({franquia?.taxa_garcom}%) {getTaxa().toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                      <b>Total:</b>
                      <b>{(getTotal()+getTaxa()).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                    </div>
                  </div>

                  <Hr/>
                  
                  {comanda == null ? (
                    <div>
                      <Button background={empresa?.cor_primaria} width={"100%"} onClick={() => {fecharComanda()}}>Finalizar Comanda</Button>
                      <Hr/>
                      <Button background={empresa?.cor_primaria} width={"100%"} onClick={() => {navigate(cardappyoUrl)}} outline={true}>Continuar Pedindo</Button>
                    </div>
                  ) : (
                    <div style={{background: 'green', color: 'white', padding: '4px', borderRadius: '6px', textAlign: 'left', display: 'flex', alignItems: 'center'}}>
                      &nbsp;<i className="fa fa-check-circle" style={{fontSize: '14pt'}}></i>&nbsp;&nbsp;
                      <b>Pague a conta no balcão ou chame um garçom, e informe que é a {mesa?.nome}.</b>
                    </div>
                  )}

                </div>
              </div>
            </>
          ) : (null)
        )}
      </div>
      <BottomNavigation activeColor={empresa?.cor_primaria} activeTab={2} />
    </>
  )
}