import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { FormLabel, Radio, RadioGroup } from '@mui/material';
import Api from '../../Api';
import Utils from '../../Utils';
import { ToastContainer } from 'react-toastify';
import InputMask from 'react-input-mask';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://cardappyo.tekhnodevelop.com/">
            Cardappyo
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
        {' | '}
        <Link color="inherit">
            Política de Privacidade
        </Link>
        {' | '}
        <Link color="inherit">
            Termo de Uso
        </Link>
        </Typography>
    );
}

const defaultTheme = createTheme();

export default () => {

    const navigate = useNavigate();     

    const {code_affiliate} = useParams(); 

    const [tipoCadastro, setTipoCadastro] = React.useState('F');
    const [acceptTerms, setAcceptTerms] = React.useState(false);
    const [location, setLocation] = React.useState({ latitude: null, longitude: null });

    React.useEffect(() => {
        if(code_affiliate){
            localStorage.setItem("CODE_AFFILIATE", code_affiliate);
        }
    }, [])

    React.useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
            (position) => {
              setLocation({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              });
            },
            (error) => {
              Utils.notify("error",'Erro ao obter a localização:', error.message);
            }
          );
        } else {
          Utils.notify("error",'Seu navegador não suporta a API Geolocation.');
        }
    }, []);

    const handleChangeTipoCadastro = (event) => {
        setTipoCadastro(event.target.value);
    };
    
    const handleAcceptTerms = (event) => {
        setAcceptTerms(event.target.checked);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let usuario = {
            tipo_cadastro: data.get('tipo_cadastro'),
            firstname: data.get('firstName'),
            lastname: data.get('lastName'),
            email: data.get('email'),
            password: data.get('password'),
            password_c: data.get('confirm-password'),
            phone: data.get('phone'),
            nome_bussness: data.get('nome_bussness'),
            cpf: data.get('cpf'),
            cnpj: data.get('cnpj'),
            accept_terms: data.get('accept_terms'),
            keybind: Utils.transformToUrl(data.get("nome_bussness")),
            latitude: location.latitude,
            longitude: location.longitude,
            codigo_afiliado: code_affiliate,
        }

        if(usuario.firstname?.trim()?.length <= 0){
            Utils.notify("error", "Digite seu nome.")
            return;
        }

        if(usuario.lastname?.trim()?.length <= 0){
            Utils.notify("error", "Digite seu sobrenome.")
            return;
        }

        if(usuario.email?.trim()?.length <= 0){
            Utils.notify("error", "Digite seu email.")
            return;
        }

        if(usuario.password?.trim()?.length <= 0){
            Utils.notify("error", "Digite sua senha.")
            return;
        }

        if(usuario.password != usuario.password_c){
            Utils.notify("error", "As senhas não se coincidem.")
            return;
        }
  
        if(usuario.phone?.trim()?.length <= 0){
            Utils.notify("error", "Digite seu celular.")
            return;
        }

        if(usuario.nome_bussness?.trim()?.length <= 0){
            Utils.notify("error", "Digite o nome do estabelecimento.")
            return;
        }

        switch(usuario.tipo_cadastro){
            case "F":
                if(usuario.cpf?.trim()?.length <= 0){
                    Utils.notify("error", "Digite seu CPF.")
                    return;
                }
                break;
            case "J":
                if(usuario.cnpj?.trim()?.length <= 0){
                    Utils.notify("error", "Digite seu CNPJ.")
                    return;
                }
                if(!Utils.validCNPJ(usuario.cnpj)){
                    Utils.notify("error", "CNPJ inválido.")
                    return;
                }
                break;
        }

        if(usuario.accept_terms == null){
            Utils.notify("error", "Você precisa concordar com os termos e políticas.")
            return;
        }

        if(usuario?.tipo_cadastro == "J"){
            let responseCnpj = await Api.admin.buscarCnpj({cnpj: usuario.cnpj});
            usuario["dados_empresa"] = responseCnpj?.data?.data;
        }

        let responseUsuario = await Api.admin.register({usuario: usuario});

        if(!responseUsuario?.data?.success){
            Utils.notify("error", responseUsuario?.data?.message);
            return;
        }else{
            Utils.notify("success", responseUsuario?.data?.message);
            let token = responseUsuario?.data?.data?.token;
            localStorage.setItem("CARDAPPYO_ACCESS_TOKEN", token);
            let timeout = setTimeout(() => {
                clearTimeout(timeout);
                if(code_affiliate){
                    navigate("/admin")
                }else{
                    window.location.reload();
                }
            }, 1500)
            return;
        }

    };

    return (
        <ThemeProvider theme={defaultTheme}>
        <ToastContainer style={{zIndex: '999999999'}}/>
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
            <Avatar sx={{ m: 1, bgcolor: 'orange' }}>
                <RestaurantMenuIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Cardappyo - Registro
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                <RadioGroup
                    row
                    aria-labelledby="tipo_cadastro"
                    name="tipo_cadastro"
                    id='tipo_cadastro'
                    value={tipoCadastro}
                    onChange={handleChangeTipoCadastro}
                    style={{marginLeft: '20px'}}
                >
                    <FormControlLabel value="F" control={<Radio />} label="Pessoa Física" />
                    <FormControlLabel value="J" control={<Radio />} label="Pessoa Jurídica" />
                </RadioGroup>
                <Grid item xs={12} sm={6}>
                    <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="Primeiro Nome"
                    autoFocus
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                    required
                    fullWidth
                    id="lastName"
                    label="Segundo Nome"
                    name="lastName"
                    autoComplete="family-name"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                    required
                    fullWidth
                    id="email"
                    label="Seu Email"
                    name="email"
                    autoComplete="email"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                    required
                    fullWidth
                    name="password"
                    label="Sua Senha"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                    required
                    fullWidth
                    name="confirm-password"
                    label="Confirmar Senha"
                    type="password"
                    id="confirm-password"
                    autoComplete="confirm-password"
                    />
                </Grid>
                <Grid item xs={12}>

                <InputMask mask="(99) 99999-9999" maskChar="_">
                {() => <TextField
                    required
                    fullWidth
                    name="phone"
                    label="Seu Celular"
                    type="phone"
                    id="phone"
                    autoComplete="phone"
                    />}
                </InputMask>

                </Grid>
                <Grid item xs={12}>
                    <TextField
                    required
                    fullWidth
                    name="nome_bussness"
                    label="Nome do Estabelecimento"
                    type="nome_bussness"
                    id="nome_bussness"
                    autoComplete="nome_bussness"
                    />
                </Grid>
                {tipoCadastro == "F" ? (
                    <Grid item xs={12}>
                        <InputMask mask="999.999.999-99" maskChar="_">
                            {() => <TextField
                            required
                            fullWidth
                            name="cpf"
                            label="Seu CPF"
                            type="cpf"
                            id="cpf"
                            autoComplete="cpf"
                            />}
                        </InputMask>
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <InputMask mask="99.999.999/9999-99" maskChar="_">
                            {() => <TextField
                            required
                            fullWidth
                            name="cnpj"
                            label="Seu CNPJ"
                            type="cnpj"
                            id="ccnpjpf"
                            autoComplete="cnpj"
                            />}
                        </InputMask>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <FormControlLabel
                    control={<Checkbox value={acceptTerms} onChange={handleAcceptTerms} name='accept_terms' id='accept_terms' color="primary" />}
                    label="Eu li e concordo com o termo e política do sistema."
                    />
                </Grid>
                </Grid>
                <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                >
                Registrar-se
                </Button>
                <Grid container justifyContent="flex-end">
                <Grid item>
                    <Link href="javascript:void(0)" onClick={() => {navigate("/login")}} variant="body2">
                    já tem uma conta? Entrar
                    </Link>
                </Grid>
                </Grid>
            </Box>
            </Box>
            <Copyright sx={{ mt: 5 }} />
        </Container>
        </ThemeProvider>
    );
}