import React, { useContext, useEffect } from 'react';
import Api from '../../Api';
import Utils from '../../Utils';
import { MainContext } from '../../helpers/MainContext';
import { ToastContainer } from 'react-toastify';

export default ({callback}) => {

    const { user, audio, muteAudio } = useContext(MainContext);

    useEffect(() => {

        solicitarPermissaoNotificacao();

        const fetchAnalyseNotificateNewPedido = async () => {
            let responseNotificationNewPedido = await Api.admin.getNotificationNewPedido({id_cardapio: user?.id_cardapio});
            let notificate = responseNotificationNewPedido?.data?.data == 1;
            if(notificate){
                Utils.notify("success", "Você tem um novo pedido");
                audio.volume = 1;
                muteAudio();
                enviarNotificacao();
                callback();
                await Api.admin.setNotificationNewPedido({id_cardapio: user?.id_cardapio});
            }
        };

        callback();
        fetchAnalyseNotificateNewPedido();
 
        const intervalId = setInterval(fetchAnalyseNotificateNewPedido, 3000);
    
        return () => clearInterval(intervalId);
    }, []);

    function enviarNotificacao() {
        if ('Notification' in window && 'vibrate' in navigator) {
            const options = {
                body: 'Novo Pedido',
                icon: user?.logo_pequena,
            };
            const notification = new Notification(user?.razao_social, options);
            navigator.vibrate([200, 100, 200]);
        }
    }

    const solicitarPermissaoNotificacao = () => {
        if ('Notification' in window) {
            Notification.requestPermission().then(function (permission) {
                if (permission === 'granted') {
                    console.log('Permissão para notificações concedida!');
                }
            });
        }
    }

    return (<><ToastContainer style={{zIndex: '999999999'}}/></>)
}
