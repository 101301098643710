import axios from "axios";
import Environment from "./Environment";

const API_BASE = Environment.API_BASE;

const Api = {
    getImagem: (id_imagem) => {
        return `${API_BASE}/imagem/${id_imagem}`;
    },
    insertImagem: async ({imagemData}) => {
        try {
            const formData = new FormData();
            formData.append('imagem', imagemData);
            const response = await axios.post(`${API_BASE}/sua-rota-de-insercao-de-imagem`, formData, {
                headers: {
                    ...Environment.HEADERS,
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        } catch (err) {
            throw err;
        }
    },
    setUserMode: async ({id_mesa, device_id, mode}) => {
        return await axios.post(`${API_BASE}/cardapio/update-user-mode`, {id_mesa, device_id, mode}, Environment.HEADERS).then(async (response) => {
            return await response;
        }).catch(err => {
            return err;
        });
    },
    getCardappyo: async ({keybind: keybind}) => {
        return await axios.get(`${API_BASE}/cardapio/${keybind}`, {}, Environment.HEADERS).then(async (response) => {
            return await response;
        }).catch(err => {
            return err;
        });
    },
    getEmpresa: async ({id_empresa: id_empresa}) => {
        return await axios.get(`${API_BASE}/empresa/${id_empresa}`, {}, Environment.HEADERS).then(async (response) => {
            return await response;
        }).catch(err => {
            return err;
        });
    },
    getFilial: async ({id_empresa: id_empresa, id_filial: id_filial}) => {
        return await axios.get(`${API_BASE}/filial/${id_empresa}/${id_filial}`, {}, Environment.HEADERS).then(async (response) => {
            return await response;
        }).catch(err => {
            return err;
        });
    },
    getCategorias: async ({id_cardapio: id_cardapio}) => {
        return await axios.get(`${API_BASE}/categoria/${id_cardapio}`, {}, Environment.HEADERS).then(async (response) => {
            return await response;
        }).catch(err => {
            return err;
        });
    },
    getItems: async ({id_cardapio: id_cardapio}) => {
        return await axios.get(`${API_BASE}/item/${id_cardapio}`, {}, Environment.HEADERS).then(async (response) => {
            return await response;
        }).catch(err => {
            return err;
        });
    },
    getAdicionais: async ({id_item: id_item}) => {
        return await axios.get(`${API_BASE}/item/adicionais/${id_item}`, {}, Environment.HEADERS).then(async (response) => {
            return await response;
        }).catch(err => {
            return err;
        });
    },
    getCupom: async ({id_cardapio: id_cardapio, nome: nome}) => {
        return await axios.get(`${API_BASE}/cupom/${id_cardapio}/${nome}`, {}, Environment.HEADERS).then(async (response) => {
            return await response;
        }).catch(err => {
            return err;
        });
    },
    createPedido: async ({pedido: pedido, id_cardapio: id_cardapio, id_empresa: id_empresa, id_filial: id_filial, id_cupom: id_cupom}) => {
        return await axios.post(`${API_BASE}/pedido/create`, {pedido, id_cardapio, id_empresa, id_filial, id_cupom}, Environment.HEADERS).then(async (response) => {
            return await response;
        }).catch(err => {
            return err;
        });
    },
    getPedidos: async ({id_cardapio: id_cardapio, id_empresa: id_empresa, id_filial: id_filial, id_mesa: id_mesa}) => {
        return await axios.get(`${API_BASE}/pedido/${id_cardapio}/${id_empresa}/${id_filial}/${id_mesa}`, {}, Environment.HEADERS).then(async (response) => {
            return await response;
        }).catch(err => {
            return err;
        });
    },
    getPedidosByUserId: async ({id_cardapio: id_cardapio, id_empresa: id_empresa, id_filial: id_filial, user_id: user_id}) => {
        return await axios.get(`${API_BASE}/pedido/${id_cardapio}/${id_empresa}/${id_filial}/user/${user_id}`, {}, Environment.HEADERS).then(async (response) => {
            return await response;
        }).catch(err => {
            return err;
        });
    },
    getMesa: async ({id_cardapio: id_cardapio, id_empresa: id_empresa, id_filial: id_filial, id_mesa: id_mesa}) => {
        return await axios.get(`${API_BASE}/mesa/${id_cardapio}/${id_empresa}/${id_filial}/${id_mesa}`, {}, Environment.HEADERS).then(async (response) => {
            return await response;
        }).catch(err => {
            return err;
        });
    },
    addPessoaMesa: async ({id_mesa: id_mesa, pessoa: pessoa}) => {
        return await axios.post(`${API_BASE}/mesa/add-pessoa`, {id_mesa: id_mesa, pessoa: pessoa}, Environment.HEADERS).then(async (response) => {
            return await response;
        }).catch(err => {
            return err;
        });
    },
    getPessoaMesa: async ({id_mesa: id_mesa}) => {
        return await axios.get(`${API_BASE}/mesa/${id_mesa}/pessoas`, {}, Environment.HEADERS).then(async (response) => {
            return await response;
        }).catch(err => {
            return err;
        });
    },
    getComandaItens: async ({ids_pedidos}) => {
        return await axios.post(`${API_BASE}/pedido/comanda-items`, {ids_pedidos: ids_pedidos}, Environment.HEADERS).then(async (response) => {
            return await response;
        }).catch(err => {
            return err;
        });
    },
    createComanda: async ({comanda}) => {
        return await axios.post(`${API_BASE}/pedido/comanda-create`, {comanda: comanda}, Environment.HEADERS).then(async (response) => {
            return await response;
        }).catch(err => {
            return err;
        });
    },
    getComanda: async ({id_mesa: id_mesa}) => {
        return await axios.get(`${API_BASE}/pedido/comanda/${id_mesa}`, {}, Environment.HEADERS).then(async (response) => {
            return await response;
        }).catch(err => {
            return err;
        });
    },
    getPedidoStatus: async ({id_pedido: id_pedido}) => {
        return await axios.get(`${API_BASE}/pedido/status/${id_pedido}`, {}, Environment.HEADERS).then(async (response) => {
            return await response;
        }).catch(err => {
            return err;
        });
    },
    admin: {
        getUser: async () => {
            return await axios.get(`${API_BASE}/admin/get`, Environment.HEADERS).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        getAuth: async () => {
            return await axios.post(`${API_BASE}/admin/auth`, {}, Environment.HEADERS).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        login: async ({email, password}) => {
            return await axios.post(`${API_BASE}/admin/login`, {email, password}, Environment.HEADERS).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        register: async ({usuario}) => {
            return await axios.post(`${API_BASE}/admin/register`, {usuario}, Environment.HEADERS).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        buscarCnpj: async ({cnpj}) => {
            return await axios.get(`${API_BASE}/admin/buscar-cnpj/${cnpj?.replace("-", "")?.replace("/", "")?.replace(".", "").replace(".", "")}`, {}, {}).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        getPedidos: async ({id_cardapio, id_empresa, id_filial}) => {
            return await axios.get(`${API_BASE}/admin/pedidos/${id_cardapio}/${id_empresa}/${id_filial}`, Environment.HEADERS).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        getPedidoItens: async ({ids_pedidos}) => {
            return await axios.post(`${API_BASE}/admin/pedido-items`, {ids_pedidos: ids_pedidos}, Environment.HEADERS).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        getNotificationNewPedido: async ({id_cardapio}) => {
            return await axios.get(`${API_BASE}/admin/notification-new-pedido/${id_cardapio}`, Environment.HEADERS).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        setNotificationNewPedido: async ({id_cardapio}) => {
            return await axios.post(`${API_BASE}/admin/notification-new-pedido-disable`, {id_cardapio}, Environment.HEADERS).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        setStatusPedido: async ({id_pedido, status}) => {
            return await axios.post(`${API_BASE}/admin/set-status-pedido`, {id_pedido, status}, Environment.HEADERS).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        getMesas: async ({id_cardapio}) => {
            return await axios.get(`${API_BASE}/admin/get-mesas/${id_cardapio}`, Environment.HEADERS).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        getPessoas: async ({ids_mesas}) => {
            return await axios.post(`${API_BASE}/admin/get-pessoas`, {ids_mesas}, Environment.HEADERS).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        getPedidosAbertos: async ({id_empresa}) => {
            return await axios.get(`${API_BASE}/admin/get-pedidos-abertos-empresa/${id_empresa}`, Environment.HEADERS).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        createMesa: async ({id_cardapio, qtd}) => {
            return await axios.post(`${API_BASE}/admin/create-mesa`, {id_cardapio, qtd}, Environment.HEADERS).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });  
        },
        fecharConta: async ({id_mesa, ids_pedidos, itens, subtotal, taxa, total, troco}) => {
            return await axios.post(`${API_BASE}/admin/fechar-conta`, {id_mesa, ids_pedidos, itens, subtotal, taxa, total, troco}, Environment.HEADERS).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });  
        },
        getFaturas: async ({id_empresa}) => {
            return await axios.get(`${API_BASE}/admin/get-faturas/${id_empresa}`, Environment.HEADERS).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        getFaturaEmAberto: async ({id_empresa}) => {
            return await axios.get(`${API_BASE}/admin/get-fatura-em-aberto/${id_empresa}`, Environment.HEADERS).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        getCicloPagamento: async ({id_empresa}) => {
            return await axios.get(`${API_BASE}/admin/get-ciclo-pagamento/${id_empresa}`, Environment.HEADERS).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        getFaturaStatus: async ({id_fatura}) => {
            return await axios.get(`${API_BASE}/admin/get-fatura-status/${id_fatura}`, Environment.HEADERS).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        getProdutos: async ({id_empresa}) => {
            return await axios.get(`${API_BASE}/admin/get-produtos/${id_empresa}`, Environment.HEADERS).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        produtoSwitchAtivo: async ({id_item, checked}) => {
            return await axios.post(`${API_BASE}/admin/produto-ativo-switch`, {id_item, checked}, Environment.HEADERS).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        produtoDelete: async ({id_item}) => {
            return await axios.post(`${API_BASE}/admin/produto-delete`, {id_item}, Environment.HEADERS).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
    }
}

export default Api;