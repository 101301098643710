import React from 'react';

export default ({ categorias, background }) => {
  return (
    categorias ? (
      <div style={{
        width: '100%',
        overflowX: 'auto', 
        whiteSpace: 'nowrap',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '15px',
        marginBottom: '15px',
        position: 'sticky',
        top: '60px',
        background: '#fcfcfc',
      }}>
        {categorias.map((c, i) => (
          <a href={`#${c.id_categoria}`} style={{ textDecoration: 'none', background: "#fff", color: '#4b4b4b', padding: '8px', margin: '4px', borderRadius: '8px', cursor: 'pointer', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 4px' }} key={i}>
            {c?.nome}
          </a>
        ))}
      </div>
    ) : null
  );
}