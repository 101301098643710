import React from 'react';
import './style.css';

import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';
import Utils from '../../Utils';

export default ({menuIndex}) => {

    const navigate = useNavigate();

    const to = (path) => {
        navigate("/admin"+path);
    }

    return (
        <div className='admin-bottom-navigation'>
            <div class={`admin-bottom-menu-item ${menuIndex == 0 ? 'admin-bottom-menu-item-active' : ''}`} style={{color: menuIndex == 0 ? Utils.getThemeDefault()?.palette?.primary?.main : "" }} onClick={() => {to('')}}>
                <div>
                    <div class="bottom-menu-icon">
                        <ReceiptLongIcon/>
                    </div>
                    <div class="text-center">
                        <b class="bottom-menu-b">Pedidos</b>
                    </div>
                </div>
            </div>
            <div class={`admin-bottom-menu-item ${menuIndex == 1 ? 'admin-bottom-menu-item-active' : ''}`} style={{color: menuIndex == 1 ? Utils.getThemeDefault()?.palette?.primary?.main : "" }} onClick={() => {to('/cardapio')}}>
                <div>
                    <div class="bottom-menu-icon">
                        <MenuBookIcon/>
                    </div>
                    <div class="text-center">
                        <b class="bottom-menu-b">Cardápio</b>
                    </div>
                </div>
            </div>
            <div class="admin-bottom-menu-item-circle" style={{background: Utils.getThemeDefault()?.palette?.primary?.main}}>
                <div>
                    <div class="bottom-menu-icon">
                        <ReceiptLongIcon/>
                    </div>
                    <div class="text-center">
                        <b class="bottom-menu-b">+Pedido</b>
                    </div>
                </div>
            </div>
            <div class={`admin-bottom-menu-item ${menuIndex == 2 ? 'admin-bottom-menu-item-active' : ''}`} style={{color: menuIndex == 2 ? Utils.getThemeDefault()?.palette?.primary?.main : "" }} onClick={() => {to('/produtos')}}>
                <div>
                    <div class="bottom-menu-icon">
                        <Inventory2Icon/>
                    </div>
                    <div class="text-center">
                        <b class="bottom-menu-b">Produtos</b>
                    </div>
                </div>
            </div>
            <div class={`admin-bottom-menu-item ${menuIndex == 9 ? 'admin-bottom-menu-item-active' : ''}`} style={{color: menuIndex == 9 ? Utils.getThemeDefault()?.palette?.primary?.main : "" }} onClick={() => {to('/perfil')}}>
                <div>
                    <div class="bottom-menu-icon">
                        <PersonIcon/>
                    </div>
                    <div class="text-center">
                        <b class="bottom-menu-b">Perfil</b>
                    </div>
                </div>
            </div>
        </div>
    )
}
