import React, { useContext } from 'react';
import './style.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { MainContext } from '../../helpers/MainContext';

export default ({open, onClose}) => {

    const { playAudio } = useContext(MainContext);

    const handleClose = () => {
        onClose()
    };

    const handleYes = () => {
        playAudio()
        onClose()
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Permitir notificação de áudio?"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Permita as notificações de áudio para não perder nenhum pedido.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Não</Button>
                <Button onClick={handleYes} autoFocus>
                    Sim
                </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
