import React, { useEffect, useState } from 'react';
import "./style.css";
import {BottomNavigation, Header, PedidoItem} from '../../components';
import Api from '../../Api';
import Utils from '../../Utils';

export default () => {

  const [cardappyo, setCardappyo] = useState(null);
  const [empresa, setEmpresa] = useState(null);
  const [franquia, setFranquia] = useState(null);
  const [pedidos, setPedidos] = useState([]);

  useEffect(() => {
    const fetchCardappyo = async () => {
      let responseCardappyo = await Api.getCardappyo({keybind: localStorage.getItem("CARDAPPYO_CURRENT_KEYBIND")});
      setCardappyo(responseCardappyo?.data?.data);
      let responseEmpresa = await Api.getEmpresa({id_empresa: responseCardappyo?.data?.data?.id_empresa});
      setEmpresa(responseEmpresa?.data?.data);
      let responseFilial = await Api.getFilial({id_empresa: responseCardappyo?.data?.data?.id_empresa, id_filial: localStorage.getItem("CARDAPPYO_CURRENT_FILIAL")});
      setFranquia(responseFilial?.data?.data);
      let responsePedidos = await Api.getPedidosByUserId({id_cardapio: responseCardappyo?.data?.data?.id_cardapio, id_empresa: responseCardappyo?.data?.data?.id_empresa, id_filial: localStorage.getItem("CARDAPPYO_CURRENT_FILIAL"), user_id: localStorage.getItem("CARDAPPYO_CURRENT_USER_ID")});
      setPedidos(responsePedidos?.data?.data);
    }
    fetchCardappyo();
  }, [])

  return (
    <>
      <Header goBack={true} title={"Meus Pedidos"} cardappyo={cardappyo} franquia={franquia} empresa={empresa}/>
      <div style={{paddingLeft: '16px', paddingRight: '16px'}}>
      {pedidos == undefined <= 0 ? (
          <>
            <h4 style={{color: '#4b4b4b', marginTop: '20px', marginBottom: '10px'}}>Não há pedidos!</h4>
          </>
        ) : (
          <>
            <h4 style={{color: '#4b4b4b', marginTop: '20px', marginBottom: '10px'}}>Meus Pedidos ({pedidos?.length})</h4>
            <div>
              {empresa && pedidos?.map((item, index) => (
                <PedidoItem key={index} pedido={item} empresa={empresa} franquia={franquia} />
              ))}
            </div>
            <br/><br/><br/>
          </>
        )}
      </div>
      <BottomNavigation activeColor={empresa?.cor_primaria} activeTab={2} />
    </>
  )
}