import React from 'react';
import "./style.css";
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import isMobile from 'is-mobile';
import AdminAppBar from '../AdminAppBar';
import AdminDrawer from '../AdminDrawer';
import Utils from '../../Utils';
import { Backdrop, Button, Container } from '@mui/material';
import AdminBottomNavigation from '../AdminBottomNavigation';
import { MainContext } from '../../helpers/MainContext';
import AdminFaturaEmAberto from '../AdminFaturaEmAberto';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Api from '../../Api';

const defaultTheme = Utils.getThemeDefault();

export default ({children, menuIndex}) => {

    const {user, faturaEmAberto} = React.useContext(MainContext);

    const [ready, setReady] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const [openFatura, setOpenFatura] = React.useState(false);
    const [showPanel, setShowPanel] = React.useState(false);
    const [daysLeft, setDaysLeft] = React.useState(0);

    React.useEffect(() => {

        const setup = async () => {
            setReady(false);
            if(faturaEmAberto){
                let response = await Api.admin.getCicloPagamento({id_empresa: user?.id_empresa});
                let endDate = new Date(response?.data?.data);
                let currentDate = new Date();
                let difference = endDate.getTime() - currentDate.getTime();
                let daysDifference = Math.ceil(difference / (1000 * 3600 * 24));
                setDaysLeft(daysDifference);
                if(daysDifference > 0){
                    setOpenFatura(true)
                }
                setShowPanel(false);
            }else{
                setShowPanel(true);
            }
            setReady(true);
        }

        setup();

    }, [])

    React.useEffect(() => {
        const isMobileDevice = isMobile();
        if (isMobileDevice) {
          setOpen(false);
        } else {
          setOpen(true);
        }
    }, []);

    const handleBackdrop = () => {
        setBackdropOpen(false);
        setOpen(false);
    }

    const toggleDrawer = () => {
        setOpen(!open);
        if(isMobile()){
            setBackdropOpen(!backdropOpen);
        }
    };

    const handleClickOpenFatura = () => {
        setOpenFatura(true);
    };
  
    const handleCloseFatura = () => {
        setOpenFatura(false);
    };

    const cssBoxDesktop = {
        backgroundColor: (theme) =>
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        width: '100%',
        height: '100vh',
        marginLeft:  '250px'
    }

    const cssBoxMobile = {
        backgroundColor: (theme) =>
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        width: '100%',
        height: '100vh',
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            {isMobile()?(
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={backdropOpen}
                    onClick={handleBackdrop}
                ></Backdrop>
            ):(null)}
            <Box sx={{ display: 'flex' }} >
                <CssBaseline />
                <AdminAppBar open={open} toggleDrawer={toggleDrawer}/>
                <AdminDrawer open={open} setOpen={setOpen} toggleDrawer={toggleDrawer} menuIndex={menuIndex}/>
                {isMobile() ? (
                    <Box
                        component="main"
                        sx={cssBoxMobile}
                        >
                        <Toolbar />
                        {daysLeft <= 0 ? (
                            <Container maxWidth="xl" sx={{ mt: 2, mb: 2 }}>
                                <AdminFaturaEmAberto show={ready} daysLeft={daysLeft} fatura={faturaEmAberto} spacing={true}/>
                            </Container>
                        ) : (daysLeft <= 5 && daysLeft >= 1) ? (
                            <>
                                <Dialog
                                    open={openFatura}
                                    onClose={handleCloseFatura}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogContent>
                                        <AdminFaturaEmAberto show={openFatura} daysLeft={daysLeft} fatura={faturaEmAberto}/>
                                    </DialogContent>
                                    <DialogActions>
                                    <Button onClick={handleCloseFatura} autoFocus>
                                        Pagar Depois
                                    </Button>
                                    </DialogActions>
                                </Dialog>
                                {children}
                            </>
                        ) : (<>{children}</>)}
                        {showPanel ? (children) : (null)}
                    </Box>
                ) : (
                    <Box
                        component="main"
                        sx={cssBoxDesktop}
                        >
                        <Toolbar />
                        {daysLeft <= 0 ? (
                            <Container maxWidth="xl" sx={{ mt: 2, mb: 2 }}>
                                <AdminFaturaEmAberto show={ready} daysLeft={daysLeft} fatura={faturaEmAberto}/>
                            </Container>
                        ) : (daysLeft <= 5 && daysLeft >= 1) ? (
                            <>
                                <Dialog
                                    open={openFatura}
                                    onClose={handleCloseFatura}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogContent>
                                        <AdminFaturaEmAberto show={openFatura} daysLeft={daysLeft} fatura={faturaEmAberto}/>
                                    </DialogContent>
                                    <DialogActions>
                                    <Button onClick={handleCloseFatura} autoFocus>
                                        Fechar
                                    </Button>
                                    </DialogActions>
                                </Dialog>
                                {children}
                            </>
                        ) : (<>{children}</>)}
                        {showPanel ? (children) : (null)}
                    </Box>
                )}
            </Box>
            {isMobile()?(
                <AdminBottomNavigation menuIndex={menuIndex}/>
            ):(null)}
        </ThemeProvider>
    )
}
