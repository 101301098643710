import * as React from 'react';
import AdminMainContainer from '../../components/AdminMainContainer';
import { AdminNotificationManager } from '../../components';

export default () => {
  return (
      <AdminMainContainer menuIndex={9}>
        <AdminNotificationManager callback={() => {}}/>
      </AdminMainContainer>
  );
}