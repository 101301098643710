import React, { useEffect, useState } from 'react';
import "./style.css";
import Button from '../Button';
import Api from '../../Api';

export default ({item, onClick, previewMode, previewCartMode, onRemove, noSelectable, quantity, mesaOnly}) => {

    return (
        previewMode ? (
            <div className={`${noSelectable ? `item-no-select` : `item`}`} onClick={onClick}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img src={item?.uri_imagem} style={{width: '60px', height: '60px', objectFit: 'cover', borderRadius: '4px'}} />
                </div>&nbsp;&nbsp;&nbsp;
                <div style={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between'}}>
                    <div>
                        <div><b style={{color: '#4b4b4b'}}>{item?.nome}</b></div>
                        {previewCartMode ? (null) : (
                            <div><p style={{color: '#4b4b4b', fontSize: '9pt', marginTop: '5px', marginBottom: '5px'}}>{item?.descricao}</p></div>
                        )}
                        <div><b style={{color: 'green'}}>{Number(item?.preco).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></div>
                        <div>
                            {item?.adicionais?.length > 0 ? (
                                <b style={{fontSize: '8pt', color: '#4b4b4b'}}>Adicionais: {item?.adicionais?.map(item => `x${item.quantity} ${item.nome}`).join(', ')}</b>
                            ) : (null)}
                        </div>
                        {quantity ? (<div><p style={{color: '#4b4b4b', fontSize: '9pt', marginTop: '5px', marginBottom: '5px'}}>Quandidade: <b style={{color: 'black'}}>x{quantity}</b></p></div>) : (null)}
                    </div>&nbsp;
                    {onRemove ? (
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Button background={"red"} outline={true} onClick={onRemove}>
                                <i className='fa fa-trash'></i>
                            </Button>
                        </div>
                    ) : (null)}
                </div>
            </div>
        ) : (
            <div className='item' onClick={onClick}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img src={item?.uri_imagem} style={{width: '60px', height: '60px', objectFit: 'cover', borderRadius: '4px'}} />
                </div>&nbsp;&nbsp;&nbsp;
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div>
                    <div><b style={{color: '#4b4b4b'}}>{item?.nome}</b></div>
                    <div><p style={{color: '#4b4b4b', fontSize: '9pt', marginTop: '5px', marginBottom: '5px'}}>{item?.descricao}</p></div>
                    <div><b style={{color: 'green'}}>{Number(item?.preco).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></div>
                    <div style={{display: 'flex', alignItems: 'center', color: 'gray', fontSize: '10pt', marginTop: '5px'}}>
                        {!mesaOnly ? (
                            <>
                                <label>
                                    <i class="fa fa-clock-o"></i>&nbsp;
                                    {item?.tempo_de_preparo}min
                                    </label>
                                &nbsp;|&nbsp;
                            </>
                        ) : (null)}
                        <label>
                        <i class="fa fa-user"></i>&nbsp;
                        {item?.qtd_pessoas} pessoa(s)
                        </label>
                    </div>
                    </div>
                </div>
            </div>
        )
    )
}
