import React, { useState, useEffect } from 'react';
import './style.css';
import Button from '../Button';

export default ({ children, open, title, onClose, onConfirm, buttonCancelText, buttonConfirmText, buttonConfirmBackground, buttonConfirmIcon, noCancellable}) => {
  const handleBackdropClick = (e) => {
    if(noCancellable) return;
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('click', handleBackdropClick);
    }

    return () => {
      document.removeEventListener('click', handleBackdropClick);
    };
  }, [open, onClose]);

  return (
    <>
      {open ? (
        <div className='backdrop' onClick={handleBackdropClick}>
          <div className='modal'>
            <div className='modal-header'>
                {title}
            </div>
            <div className='modal-body'>{children}</div>
            <div className='modal-footer'>
              {!noCancellable ? (
                <>
                  <Button onClick={onClose} >{buttonCancelText}</Button>&nbsp;&nbsp;
                </>
              ) : (null)}
              <Button onClick={onConfirm} background={buttonConfirmBackground}>{buttonConfirmIcon}{buttonConfirmText}</Button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};