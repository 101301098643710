import { TextField } from '@mui/material';
import React from 'react';
import { NumericFormat } from 'react-number-format';

export default ({onChange, value, style}) => {
    
    return <NumericFormat 
        style={style}
        thousandSeparator="."
        decimalSeparator=","
        prefix="R$ "
        decimalScale={2}
        fixedDecimalScale={true}
        allowNegative={false}
        value={value}
        onChange={onChange}
        customInput={TextField}
    />;

}