import * as React from 'react';
import AdminPedidos from '../../components/AdminPedidos';
import AdminMainContainer from '../../components/AdminMainContainer';
import Api from '../../Api';
import { MainContext } from '../../helpers/MainContext';
import { AdminNotificationManager } from '../../components';

export default () => {

    const { user, faturas, faturaEmAberto } = React.useContext(MainContext);

    const [pedidos, setPedidos] = React.useState([]);
    const [itens, setItens] = React.useState([]);

    const fetchPedidos = async () => {
        let responsePedidos = await Api.admin.getPedidos({
            id_empresa: user?.id_empresa,
            id_cardapio: user?.id_cardapio,
            id_filial: user?.id_filial,
        });
        setPedidos([]);
        setPedidos(responsePedidos?.data?.data?.reverse());
        let ids_pedidos = [];
        for(let i = 0; i < responsePedidos?.data?.data.length; i++){
            let p = responsePedidos?.data?.data[i];
            ids_pedidos.push(p?.id_pedido);
        }
        let responsePedidoItens = await Api.admin.getPedidoItens({
            ids_pedidos: ids_pedidos
        })
        setItens([]);
        setItens(responsePedidoItens?.data?.data);
    }

    return (
        <AdminMainContainer menuIndex={0}>
            <AdminNotificationManager callback={fetchPedidos}/>
            <AdminPedidos pedidos={pedidos} itens={itens} fetchPedidos={fetchPedidos}/>
        </AdminMainContainer>
    );
}