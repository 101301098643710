import React from 'react';
import "./style.css";

export default ({type, placeholder, value, onChange}) => {
  return (
    <div style={{width: '100%'}}>
        <input className='input' type={type} placeholder={placeholder} value={value} onChange={onChange} />
    </div>
  )
}
