import React, { useEffect, useState } from 'react';
import "./style.css";
import {BottomNavigation, Header, Item, Hr, Button, Modal} from '../../components';
import useCart from '../../globals/useCart';
import useAddress from '../../globals/useAddress';
import Api from '../../Api';
import Input from '../../components/Input';
import InputMask from 'react-input-mask';
import Utils from '../../Utils';
import {ToastContainer} from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default () => {

  const navigate = useNavigate();

  const { cart, clearCart, getCartPrice, removeItemFromCart, getCartFromLocalStorage } = useCart(localStorage.getItem("CARDAPPYO_CURRENT_KEYBIND"));
  const {addressList, addAddress, getAddressesFromLocalStorage} = useAddress();

  const [ready, setReady] = useState(false);
  const [currentCart, setCurrentCart] = useState(null);
  const [cardappyo, setCardappyo] = useState(null);
  const [empresa, setEmpresa] = useState(null);
  const [franquia, setFranquia] = useState(null);
  const [quantity, setQuantity] = useState(0);
  const [cupom, setCupom] = useState(null);
  const [inputCupom, setInputCupom] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [total, setTotal] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenAddress, setOpenModalAddress] = useState(false);
  const [cep, setCep] = useState("");
  const [address, setAddress] = useState(null);
  const [addressNumber, setAddressNumber] = useState(null);
  const [addressComplement, setAddressComplement] = useState(null);
  const [addressSelected, setAddressSelected] = useState(null);
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [frete, setFrete] = useState(0);
  const [paymentMethodSelected, setPaymentMethodSelected] = useState(0);
  const [serviceMethod, setServiceMethod] = useState(0);
  const [needChange, setNeedChange] = useState(false);
  const [needChangeInput, setNeedChangeInput] = useState(null);
  const [sendWhatsappLink, setSendWhatsappLink] = useState(true);
  const [modalOpenPedidoFeito, setModalOpenPedidoFeito] = useState(false);
  const [comanda, setComanda] = useState(null);

  useEffect(() => {
    setReady(false);
    const fetchCardappyo = async () => {
      let responseCardappyo = await Api.getCardappyo({keybind: localStorage.getItem("CARDAPPYO_CURRENT_KEYBIND")});
      setCardappyo(responseCardappyo?.data?.data);
      let responseEmpresa = await Api.getEmpresa({id_empresa: responseCardappyo?.data?.data?.id_empresa});
      setEmpresa(responseEmpresa?.data?.data);
      let responseFilial = await Api.getFilial({id_empresa: responseCardappyo?.data?.data?.id_empresa, id_filial: localStorage.getItem("CARDAPPYO_CURRENT_FILIAL")});
      setFranquia(responseFilial?.data?.data);
      setCurrentCart(cart);
      await fetchComanda();
    }
    fetchCardappyo();
    setQuantity(getMaxCountItems())
    handleTotalCart();
    setReady(true);
  }, [])

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });

          handleSetFretePrice();

        },
        (error) => {
          Utils.notify("error",'Erro ao obter a localização:', error.message);
        }
      );
    } else {
      Utils.notify("error",'Seu navegador não suporta a API Geolocation.');
    }
  }, [franquia]);

  const fetchComanda = async () => {
    let responseComanda = await Api.getComanda({id_mesa: localStorage.getItem("CARDAPPYO_CURRENT_MESA")});
    if(responseComanda?.data?.success){
      setComanda(responseComanda?.data?.data);
    }
  }

  const handleSetFretePrice = () => {
    setFrete(0)
  }

  const removeItem = (item) => {
    removeItemFromCart(item?.id);
    setCurrentCart(getCartFromLocalStorage());
    setQuantity(getMaxCountItems())
    handleTotalCart();
    checkCupomValorAndRemove();
  }

  const checkCupomValorAndRemove = () => {
    if(cupom){
      if(getCartPrice() < Number(cupom?.valor_minimo)){
        Utils.notify("error",`O valor mínimo do cupom é ${Number(cupom?.valor_minimo).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`)
        setCupom(null);
        setDiscount(0);
        setInputCupom("");
        setTotal(getCartPrice());
      }
    }
  }

  const getMaxCountItems = () => {
    let cart = getCartFromLocalStorage();
    let count = 0;
    for(let i = 0; i < cart.length; i++){
      count += cart[i].quantity;
    }
    return count;
  }

  const handleTotalCart = () => {
    let t = getCartPrice() - discount;
    setTotal(t);
  }

  const handleApplyDiscount = async () => {
    if(inputCupom == null || inputCupom?.trim().length <= 0) {
      Utils.notify("error", "digite o código do cumpom.")
      return;
    };

    let cupomResponse = await Api.getCupom({
      id_cardapio: cardappyo?.id_cardapio,
      nome: inputCupom
    })

    if(cupomResponse?.response?.status == 404){
      Utils.notify("error", cupomResponse?.response?.data?.data)
      return;
    }

    let cupomData = cupomResponse?.data?.data;

    let dataExpiracaoDate = new Date(cupomData?.data_expiracao);
    let dataAtual = new Date();

    if (dataExpiracaoDate < dataAtual) {
      Utils.notify("error", "cupom já expirado.")
      return;
    } 

    if(getCartPrice() < Number(cupomData?.valor_minimo)){
      Utils.notify("error",`O valor mínimo do cupom é ${Number(cupomData?.valor_minimo).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`)
    }else{
      switch(cupomData?.tipo){
        case "P":
          let vPercent = (getCartPrice() * cupomData?.valor) / 100;
          setDiscount(vPercent);     
          let tValorPercent = getCartPrice() - vPercent; 
          setTotal(tValorPercent);   
          break;
        case "V":
          setDiscount(Number(cupomData?.valor));
          let tValor = getCartPrice() - Number(cupomData?.valor);
          setTotal(tValor);
          break;
      }
      Utils.notify("success", "cupom aplicado com sucesso.")
      setCupom(cupomData);
    }

  }

  //==============================

  const closeModal = () => {
    setModalOpen(false);
  };

  const getMyDistanceToPoint = () => {
    let franquiaLocation = {
      latitude: Number(franquia?.latitude),
      longitude: Number(franquia?.longitude)
    }
    let distance = Utils.calculateDistance(location, franquiaLocation).toFixed(2);
    return distance;
  }

  const handleFazerPedido = async () => {
    await fetchComanda();

    if(Number(getMyDistanceToPoint()) > franquia?.quadro_km){
      Utils.notify("error", "Você não está no(a): "+franquia?.nome_fantasia+".");
      return;
    }

    if(comanda){
      Utils.notify("error", "Comanda aberta nesta mesa; solicite ao garçom ou ao balcão para fechá-la.");
      return;
    }

    handlePedidoConfirm();
  }

  const handlePedidoConfirm = async () => {

    let pedido = {
      id_ref: Utils.makeuidd(7),
      tipo: "Mesa",
      id_mesa: localStorage.getItem("CARDAPPYO_CURRENT_MESA"),
      nome: localStorage.getItem("CARDAPPYO_CURRENT_USERNAME"),
      device_id: Utils.getDeviceId(),
      user_id: localStorage.getItem("CARDAPPYO_CURRENT_USER_ID"),
      endereco: null,
      pagamento: null,
      troco: null,
      frete: 0,
      subtotal: getCartPrice(),
      desconto: discount,
      total: total,
      itens: getCartFromLocalStorage()
    };

    pedido["latitude"] = location.latitude;
    pedido["longitude"] = location.longitude;

    let times = [];

    for(let i = 0; i < pedido.itens.length; i++){
      let it = pedido.itens[i];
      let it_times = it.item.tempo_de_preparo.split('-');
      for(let j = 0; j < it_times.length; j++){
        times.push(Number(it_times[j]))
      }
    }

    let sumTimes = 0;
    let mediaTimes = 0;
    let maxTimes = Math.max(...times);
    for(let i = 0; i < times.length; i++){
      sumTimes += times[i];
    }
    mediaTimes = parseInt(sumTimes / times.length);

    const formatIntToZero = (number) => {
      if(number < 10){
        return "0"+(number);
      }else{
        return number;
      }
    }

    let currentDateEstimate1 = new Date();
    let currentDateEstimate2 = new Date();
    let minuteToSum1 = mediaTimes;
    let minuteToSum2 = maxTimes;

    currentDateEstimate1.setMinutes(currentDateEstimate1.getMinutes() + minuteToSum1);
    currentDateEstimate2.setMinutes(currentDateEstimate2.getMinutes() + minuteToSum2);

    let estimateDelivery = formatIntToZero(currentDateEstimate1.getHours()) + ":" + formatIntToZero(currentDateEstimate1.getMinutes()) + "-" + formatIntToZero(currentDateEstimate2.getHours()) + ":" + formatIntToZero(currentDateEstimate2.getMinutes());

    pedido["estimativa_entrega"] = estimateDelivery;
    
    let responseCreatePedido = await Api.createPedido({
      pedido,
      id_cardapio: cardappyo.id_cardapio,
      id_empresa: empresa.id_empresa,
      id_filial: franquia.id_filial,
      id_cupom: (cupom) ? cupom.id_cupom : '',
    })

    clearCart();

    Utils.notify("success", "Pedido feito, jájá levaremos até você!");

    setModalOpenPedidoFeito(true);

    let timeout = setTimeout(() => {
      clearTimeout(timeout);
      navigate("/comanda");      
    }, 2500)

  }

  //=============================

  const closeModalAddress = () => {
    setOpenModalAddress(false);
  };

  const handleAddressModal = () => {
    setOpenModalAddress(true);
  }

  const handleAddressModdalConfirm = () => {

    if(!Utils.validCEP(cep)){
      Utils.notify("error", "Digite um CEP válido.");
      return;
    }

    if(address?.cep?.trim().length <= 0){
      Utils.notify("error", "Digite seu CEP.");
      return;
    }

    if(address?.logradouro?.trim().length <= 0){
      Utils.notify("error", "Digite seu logradouro.");
      return;
    }

    if(address?.bairro?.trim().length <= 0){
      Utils.notify("error", "Digite seu bairro.");
      return;
    }

    if(!addressNumber){
      Utils.notify("error", "Digite o numero.");
      return;
    }

    let lastId = Utils.makeid(9);

    addAddress({
      ...address,
      id: lastId,
      numero: addressNumber,
      complemento: addressComplement
    })

    let lastAddressInserted = getAddressesFromLocalStorage().filter(it => { return it.id == lastId })[0];

    setAddressSelected(lastAddressInserted.id);

    Utils.notify("success", "Endereço salvo com sucesso.");
    setOpenModalAddress(false);
  }

  const handleSetCep = (e) => {
    setCep(e.target.value);

    if(!Utils.validCEP(e.target.value)){
      return;
    }

    fetch(`https://viacep.com.br/ws/${e.target.value}/json/`)
    .then(response => response.json())
    .then(data => {
      setAddress(data);
    })
    .catch(error => {
      //console.error('Erro ao verificar o CEP:', error);
    });

  }

  const handleChangeLogradouro = (e) => {
    const logradouro = e.target.value;
    setAddress({
      ...address,
      logradouro: logradouro, 
    });
  }

  const handleChangeBairro = (e) => {
    const bairro = e.target.value;
    setAddress({
      ...address,
      bairro: bairro, 
    });
  }

  const handleSetPaymentMethod = (e) => {
    setPaymentMethodSelected(e.target.value);
    if(e.target.value == 1){//dinheiro
      setNeedChange(true);
    }else{
      setNeedChange(false);
    }
  }

  const handleServiceMethod = (e) => {
    setServiceMethod(e.target.value);
    switch(e.target.value){
      case "0":
        handleSetFretePrice();
        break;
      case "1":
        setFrete(0);
        break;
    }
  }

  const closeModalPedidoFeito = () => {
    setModalOpenPedidoFeito(false);
  }

  return (
    <>
      <ToastContainer style={{zIndex: '999999999'}}/>
      <Modal 
        open={modalOpen} 
        onClose={closeModal} 
        title={`Escolher forma de pagamento`}
        buttonConfirmBackground={empresa?.cor_primaria}
        buttonCancelText={"Cancelar"}
        buttonConfirmText={"Fazer Pedido"}
        onConfirm={handlePedidoConfirm}
        buttonConfirmIcon={<i></i>}
        >
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px'}}>
          <select className='select' value={serviceMethod} onChange={e => handleServiceMethod(e)}>
            <option value={0}>Entrega</option>
            <option value={1}>Retirada</option>
          </select>&nbsp;&nbsp;
          <select className='select' value={paymentMethodSelected} onChange={e => handleSetPaymentMethod(e)}>
            <option value={0}>(Pagamento)</option>
            <option value={1}>Dinheiro</option>
            <option value={2}>Pix</option>
            <option value={3}>Cartão Débito</option>
            <option value={4}>Cartão Crédito</option>
          </select>
        </div>
        {needChange ? (
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px'}}>
            <input type={"number"} className='input' placeholder={"Se precisar, troco pra quanto?"} value={needChangeInput} onChange={e => setNeedChangeInput(e.target.value)} />
          </div>
        ) : (null)}
        {serviceMethod == 0 ?
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px'}}>
            <select className='select' value={addressSelected} onChange={e => setAddressSelected(e.target.value)}>
              <option value={0}>(Selecionar endereço)</option>
              {getAddressesFromLocalStorage().reverse().map((item, i) => (
                <option key={i} value={item.id}>{item?.numero+""+(item?.complemento != null ? item?.complemento : "")}, {item?.logradouro}, {item?.bairro}</option>
              ))}
            </select>&nbsp;&nbsp;
            <Button background={"gray"} onClick={() => {handleAddressModal()}}>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <i class="fa fa-map-marker"></i>
              </div>
            </Button>
          </div>
        : (null)}
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '10px', marginTop: '20px'}}>
          <input type='checkbox' checked={sendWhatsappLink} onChange={e => setSendWhatsappLink(e.target.checked)}/>
          <b style={{fontSize: '9pt'}}>&nbsp;Enviar o pedido para o whatsapp da loja?</b>
        </div>
      </Modal>
      <Modal
        open={modalOpenPedidoFeito} 
        onClose={closeModalPedidoFeito} 
        title={`Pedido realizado!`}
        buttonConfirmBackground={empresa?.cor_primaria}
        buttonCancelText={""}
        noCancellable={true}
        buttonConfirmText={"OK"}
        onConfirm={() => {navigate("/comanda")}}
        buttonConfirmIcon={<i></i>}
      >
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <img style={{width: '100%'}} src='ok.png'/>
        </div>
      </Modal>
      <Modal 
        open={modalOpenAddress} 
        onClose={closeModalAddress} 
        title={`Adicionar endereço`}
        buttonConfirmBackground={empresa?.cor_primaria}
        buttonCancelText={"Cancelar"}
        buttonConfirmText={"Adicionar"}
        onConfirm={handleAddressModdalConfirm}
        buttonConfirmIcon={<i></i>}
        >
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px'}}>
          <InputMask className='input cep' value={cep} onChange={handleSetCep} mask={"99999-999"} placeholder={"Digite seu CEP"} />
        </div>
        {address ? (
          <>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px'}}>
              <input className='input' placeholder={'Digite seu logradouro'} value={address?.logradouro || ''} onChange={handleChangeLogradouro}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px'}}>
              <input className='input' placeholder={'Digite seu bairro'} value={address?.bairro || ''} onChange={handleChangeBairro}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px'}}>
              <input className='input' type={"number"} placeholder={'Numero'} value={addressNumber} onChange={e => setAddressNumber(e.target.value)}/>&nbsp;&nbsp;
              <input className='input' type={"text"} placeholder={'Complemento'} value={addressComplement} onChange={e => setAddressComplement(e.target.value)}/>
            </div>
          </>
        ) : (null)}
      </Modal>

      <Header goBack={true} title={"Minha Sacola"} cardappyo={cardappyo} franquia={franquia} empresa={empresa}/>
      <div style={{marginLeft: '16px', marginRight: '16px'}}>
        <div>
          {quantity <= 0 ? (
            <>
              <h4 style={{color: '#4b4b4b', marginTop: '20px', marginBottom: '10px'}}>Sua sacola está vazia!</h4>
              <div style={{width: '100%', height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img src={"mesa.svg"} style={{width: '300px'}}/>
              </div>
            </>
          ) : (
            <>
              <h4 style={{color: '#4b4b4b', marginTop: '20px', marginBottom: '10px'}}>Seus Itens ({quantity})</h4>
              <div className='scroll-cart-items-cart-mesa'>
                {currentCart?.map((item, i) => (
                  <Item key={i} item={item.item} noSelectable={true} previewMode={true} quantity={item.quantity} previewCartMode={true} onRemove={() => { removeItem(item) }} onClick={() => {}} />
                ))}
              </div>
              <Hr/>
              <div style={{display: 'none', alignItems: 'center', background: '#fff', borderRadius: '8px', padding: '8px', border: 'solid 1px #f5f5f5'}}>
                <div style={{flex: 1}}>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px'}}>
                    <b style={{color: '#4b4b4b'}}><i class="fa fa-ticket" style={{transform: 'rotateZ(-45deg)', fontSize: '10pt'}}></i>&nbsp;Código de cupom</b>
                  </div>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px'}}>
                    <Input type={"text"} placeholder={"Digite o cupom"} value={inputCupom} onChange={(e) => setInputCupom(e.target.value)} />
                    <Button onClick={() => {handleApplyDiscount()}} background={empresa?.cor_primaria}><i class="fa fa-check"></i></Button>
                  </div>
                </div>
              </div>
              <Hr/>
              {ready ? 
                (<div style={{display: 'flex', alignItems: 'center', background: '#fff', borderRadius: '8px', padding: '8px', border: 'solid 1px #f5f5f5'}}>
                    <div style={{flex: 1}}>
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px'}}>
                        <b style={{color: '#4b4b4b'}}>Subtotal</b>
                        <b style={{textAlign: 'right'}}>{getCartPrice().toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                      </div>
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px'}}>
                        <b style={{color: '#4b4b4b'}}>Descontos</b>
                        <b style={{textAlign: 'right'}}>-{discount.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                      </div>
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px'}}>
                        <b style={{color: '#4b4b4b'}}>Total</b>
                        <b style={{textAlign: 'right'}}>{(total+frete) <= 0 ? (<b style={{color: 'green'}}>grátis</b>) : (total+frete).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                      </div>
                      <div style={{marginTop: '10px'}}>
                        <Button width={"100%"} background={empresa?.cor_primaria} onClick={() => {handleFazerPedido()}}>Fazer Pedido</Button>
                      </div>
                    </div>
                </div>) 
              : (null)}
            </>
          )}
        </div>
      </div>
      <BottomNavigation activeColor={empresa?.cor_primaria} activeTab={3} />
    </>
  )
}
