import React, { useContext, useEffect, useState } from 'react';
import './style.css';
import {BottomNavigation, Header, CategoryList, Modal, Item} from '../../components';
import useCart from '../../globals/useCart';
import { MainContext } from '../../helpers/MainContext';
import Api from '../../Api';
import Utils from '../../Utils';
import { useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

export default () => {

  const {keybind, filial} = useParams();

  const { } = useContext(MainContext);

  const { addItemToCart } = useCart(keybind);
  const [cardappyo, setCardappyo] = useState(null);
  const [empresa, setEmpresa] = useState(null);
  const [franquia, setFranquia] = useState(null);
  const [categorias, setCategorias] = useState(null);
  const [itens, setItens] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [permissionGranted, setPermissionGranted] = useState(false);
  const [modalLocationOpen, setModalLocationOpen] = useState(false);
  const [additionals, setAdditionals] = useState([]);
  const [savedAdditionals, setSavedAdditionals] = useState([]);
  const [username, setUsername] = useState(localStorage.getItem("CARDAPPYO_CURRENT_USERNAME"));
  const [whatsappNumber, setWhatsappNumber] = useState(localStorage.getItem("CARDAPPYO_CURRENT_WHATSAPP_NUMBER"));
  const [modalUser, setModalUser] = useState(false);

  const requestLocationPermission = async () => {
    if (navigator.geolocation) {
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        setPermissionGranted(true);
      } catch (error) {
        setPermissionGranted(false);
        setModalLocationOpen(true);
      }
    } else {
      Utils.notify("error",'Geolocalização não suportada no seu navegador.');
    }
  };

  useEffect(() => {
    if(username == null){
      setModalUser(true);
    }
  }, [])

  useEffect(() => {
    requestLocationPermission();
  }, []);

  useEffect(() => {
    const fetchCardappyo = async () => {
      let responseCardappyo = await Api.getCardappyo({keybind: keybind});
      setCardappyo(responseCardappyo?.data?.data);
      let responseEmpresa = await Api.getEmpresa({id_empresa: responseCardappyo?.data?.data?.id_empresa});
      setEmpresa(responseEmpresa?.data?.data);
      let responseCategories = await Api.getCategorias({id_cardapio: responseCardappyo?.data?.data.id_cardapio});
      setCategorias(responseCategories?.data?.data);
      let responseItems = await Api.getItems({id_cardapio: responseCardappyo?.data?.data.id_cardapio});
      setItens(responseItems?.data?.data);
      let responseFilial = await Api.getFilial({id_empresa: responseCardappyo?.data?.data?.id_empresa, id_filial: filial});
      setFranquia(responseFilial?.data?.data);
      document.title = responseFilial?.data?.data?.nome_fantasia+' - Cardappyo';
      localStorage.setItem("CARDAPPYO_CURRENT_KEYBIND", keybind);
      localStorage.setItem("CARDAPPYO_CURRENT_FILIAL", filial);
      localStorage.removeItem("CARDAPPYO_CURRENT_MESA");
      await Api.setUserMode({id_mesa: null, device_id: Utils.getDeviceId(), mode: 1});
    }
    fetchCardappyo();
  }, [])

  useEffect(() => {
    const handleSmoothScroll = (event) => {
      if (event.target.tagName === 'A' && event.target.getAttribute('href').startsWith('#')) {
        event.preventDefault();
        const targetId = event.target.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId); 
        if (targetElement) {
          window.scrollTo({
            top: targetElement.offsetTop,
            behavior: 'smooth'
          });
        }
      }
    };
    document.addEventListener('click', handleSmoothScroll);
    return () => {
      document.removeEventListener('click', handleSmoothScroll);
    };
  }, []);

  const closeModal = () => {
    setQuantity(1);
    setModalOpen(false);
  };

  const closeModalLocation = () => {
    setModalLocationOpen(false);
  };

  const handleSelectItem = async (item) => {
    if(!permissionGranted){
      requestLocationPermission();
    }else{
      setModalOpen(true);
      setSelectedItem(item);
      let responseAdicionais = await Api.getAdicionais({id_item: item?.id_item});
      setAdditionals(responseAdicionais?.data?.data);
      let addtls = [];
      for(let i = 0; i < responseAdicionais?.data?.data.length; i++){
        addtls.push({
          id_adicional: responseAdicionais?.data?.data[i].id_adicional,
          id_item: responseAdicionais?.data?.data[i].id_item,
          nome: responseAdicionais?.data?.data[i].nome,
          preco: responseAdicionais?.data?.data[i].preco,
          quantity: 0
        })
      }
      setSavedAdditionals(addtls);
    }
  }

  const handleConfirmSelectedItem = () => {
    addItemToCart({
      id: Utils.makeid(9),
      item: {
        desricao: selectedItem.desricao,
        id_cardapio: selectedItem.id_cardapio,
        id_categoria: selectedItem.id_categoria,
        id_item: selectedItem.id_item,
        nome: selectedItem.nome,
        original_preco: selectedItem?.preco,
        adicionais: savedAdditionals.filter(it => {return it.quantity > 0 && it.id_item == selectedItem?.id_item}),
        preco: (Number(selectedItem?.preco) + getPriceSavedAddidionals()) * quantity,
        qtd_pessoas: selectedItem.qtd_pessoas,
        tempo_de_preparo: selectedItem.tempo_de_preparo,
        ts_registro: selectedItem.ts_registro,
        uri_imagem: selectedItem?.uri_imagem,
      },
      quantity: quantity,
    });
    setQuantity(1);
    setModalOpen(false);
  }

  const handleAddQuantity = () => {
    setQuantity(quantity + 1);
  }

  const handleRemoveQuantity = () => {
    if(quantity > 1){ 
      setQuantity(quantity - 1);
    }
  }

  const handlePermitLocation = () => {
    requestLocationPermission();
  }

  const additionalIncrement = (additionalRef) => {
    setSavedAdditionals(savedAdditionals => {
      const updatedAdditionals = [...savedAdditionals];
      const index = updatedAdditionals.findIndex(item => item?.id_adicional === additionalRef?.id_adicional);
      if (index !== -1) {
        if(updatedAdditionals[index].quantity < additionalRef?.limite_quantidade){
          updatedAdditionals[index].quantity += 1;
        }
      }
      return updatedAdditionals;
    });
  }

  const additionalDecrement = (additionalRef) => {
    setSavedAdditionals(savedAdditionals => {
      const updatedAdditionals = [...savedAdditionals];
      const index = updatedAdditionals.findIndex(item => item?.id_adicional === additionalRef?.id_adicional);
      if (index !== -1) {
        if( updatedAdditionals[index].quantity > 0){ 
          updatedAdditionals[index].quantity -= 1;
        }
      }
      return updatedAdditionals;
    });
  }

  const getPriceSavedAddidionals = () => {
    let price = 0;
    for(let i = 0; i < savedAdditionals.length; i++){
      let sum = Number(savedAdditionals[i].preco) * Number(savedAdditionals[i].quantity)
      if(sum != 0){
        price += sum;
      }
    }
    return Number(price);
  }

  const closeModalUser = () => {
    setModalUser(false)
  }

  const handleModalUserConfirm = async () => {
    if(username == null || username?.trim()?.length <= 0){
      Utils.notify("error", "digite seu nome.");
      return;
    }

    if(whatsappNumber == null || whatsappNumber?.trim()?.length <= 0){
      Utils.notify("error", "seu numero de (whatsapp).");
      return;
    }

    localStorage.setItem("CARDAPPYO_CURRENT_USERNAME", username);
    localStorage.setItem("CARDAPPYO_CURRENT_USER_ID", Utils.makeid(50));
    localStorage.setItem("CARDAPPYO_CURRENT_WHATSAPP_NUMBER", whatsappNumber);

    setModalUser(false)
  }

  return (
    <>
      {cardappyo ? (
        <>
          <ToastContainer style={{zIndex: '999999999'}}/>
          {!permissionGranted ? (
            <Modal 
              open={modalLocationOpen}
              onClose={closeModalLocation}
              title={`Permitir Localização`}
              buttonConfirmBackground={empresa?.cor_primaria}
              buttonCancelText={"Voltar"}
              buttonConfirmText={"Permitir"}
              onConfirm={handlePermitLocation}
              buttonConfirmIcon={<i></i>}
              >
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <img src={"../address.svg"} style={{width: '300px'}}/>
                </div>
            </Modal>
          ) : (null)}
          <Modal 
            open={modalUser}
            onClose={closeModalUser}
            title={`Qual seu nome?`}
            buttonConfirmBackground={empresa?.cor_primaria}
            buttonCancelText={"Voltar"}
            buttonConfirmText={"Ok"}
            noCancellable={true}
            onConfirm={handleModalUserConfirm}
            buttonConfirmIcon={<i></i>}
            >
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '5px'}}>
              <input className='input' type='text' placeholder='Digite seu nome' value={username} onChange={e => setUsername(e.target.value)}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <input className='input' type='number' placeholder='DDD + Celular' value={whatsappNumber} onChange={e => setWhatsappNumber(e.target.value)}/>
            </div>
          </Modal>
          {selectedItem ? (
            <Modal 
              open={modalOpen} 
              onClose={closeModal} 
              title={`Adicionar`}
              buttonConfirmBackground={empresa?.cor_primaria}
              buttonCancelText={"Cancelar"}
              buttonConfirmText={" Adicionar"}
              onConfirm={handleConfirmSelectedItem}
              buttonConfirmIcon={<i className="fa fa-shopping-basket"></i>}
              >
              <Item item={selectedItem} onClick={null} previewMode={true} noSelectable={true} />
              <div style={{maxHeight: '300px', overflowY: 'auto'}}>
                {additionals && additionals.length > 0 ? (
                  <h4 style={{color: "#4b4b4b", marginBottom: '10px', marginTop: '15px'}}>Adicionais:</h4>
                ) : (null)}
                {additionals && additionals.map((item, index) => (
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      {item?.imagem ? (
                        <>
                          <img src={item?.imagem} style={{width: '30px', height: '30px', objectFit: 'cover', borderRadius: '4px', border: 'solid 1px #f5f5f5', background: '#f5f5f5'}} />
                          &nbsp;
                        </>
                      ) : (null)}
                      <b style={{fontSize: '10pt'}}>{item?.nome} - {Number(item?.preco).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: '5px'}}>
                      <div onClick={() => {additionalDecrement(item)}} style={{width: '30px', height: '30px', border: `solid 1px ${empresa?.cor_primaria}`, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: '4px'}}>
                        <b style={{color: empresa?.cor_primaria}}>-</b>
                      </div>
                      <div style={{width: '30px', height: '30px', border: `solid 1px #f5f5f5`, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>
                        <b style={{color: "gray"}}>{savedAdditionals.filter(it => {return it.id_adicional == item.id_adicional})[0].quantity}</b>
                      </div>
                      <div onClick={() => {additionalIncrement(item)}} style={{width: '30px', height: '30px', border: `solid 1px ${empresa?.cor_primaria}`, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: '4px'}}>
                        <b style={{color: empresa?.cor_primaria}}>+</b>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px', marginBottom: '10px'}}>
                <div onClick={handleRemoveQuantity} style={{background: empresa?.cor_primaria, width: '40px', height: '40px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><b style={{textAlign: 'center', fontSize: '16pt', color: 'white'}}>-</b></div>
                <div><input type={"number"} style={{width: '45px', fontSize: '16pt', textAlign: 'center', outline: 'none'}} value={quantity} onChange={(e) => setQuantity(e.target.value)} /></div>
                <div onClick={handleAddQuantity} style={{background: empresa?.cor_primaria, width: '40px', height: '40px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><b style={{textAlign: 'center', fontSize: '16pt', color: 'white'}}>+</b></div>
              </div>
              {additionals && additionals.length > 0 ? (
                  <h4 style={{color: "#4b4b4b", marginBottom: '10px', marginTop: '25px'}}>Total: <span style={{color: 'green'}}>{((Number(selectedItem?.preco) + getPriceSavedAddidionals()) * quantity).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span></h4>
              ) : (null)}
            </Modal>
          ) : (null)}

          <Header cardappyo={cardappyo} franquia={franquia} empresa={empresa}/>
          <CategoryList background={empresa?.cor_primaria} categorias={categorias} />
          <div style={{marginLeft: '16px', marginRight: '16px'}}>
            {categorias?.map((c, i) => (
              <div id={`${c.id_categoria}`}>
                <h4 key={i} style={{color: '#4b4b4b'}}>{c?.nome}</h4>
                {itens?.filter(item => { return item.id_categoria == c.id_categoria}).map((item, i) => (
                  <Item item={item} key={i} onClick={() => {handleSelectItem(item)}} />
                ))}
              </div>
            ))}
          </div>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <BottomNavigation activeColor={empresa?.cor_primaria} activeTab={0} />
        </>
      ) : (null)}
    </>
  )
}
