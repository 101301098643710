import { useState, useEffect } from 'react';

const getCartFromLocalStorage = () => {
  let keybind = localStorage.getItem("CARDAPPYO_CURRENT_KEYBIND");
  const cart = localStorage.getItem('CARDAPPYO-CART-'+keybind);
  return cart ? JSON.parse(cart) : [];
};

const useCart = (keybind) => {

  const [cart, setCart] = useState(getCartFromLocalStorage());
  const [cartPrice, setCartPrice] = useState(0);

  const addItemToCart = (item) => {
    let _cart = getCartFromLocalStorage();
    const updatedCart = [..._cart, item];
    setCart(updatedCart);
    localStorage.setItem('CARDAPPYO-CART-'+keybind, JSON.stringify(updatedCart));
  };

  const removeItemFromCart = (id) => {
    let cart = getCartFromLocalStorage();
    const updatedCart = cart.filter((c) => c.id !== id);
    setCart(updatedCart);
    localStorage.setItem('CARDAPPYO-CART-'+keybind, JSON.stringify(updatedCart));
  };

  const clearCart = () => {
    setCart([]);
    localStorage.removeItem('CARDAPPYO-CART-'+keybind);
  };

  const getCartPrice = () => {
    let cart = getCartFromLocalStorage();
    let sum = 0;
    for(let i = 0; i < cart.length; i++){
      let price = Number(cart[i].item.preco);
      let p = price;
      sum += p;
    }
    return sum;
  }

  useEffect(() => {
    let cart = getCartFromLocalStorage();
    let sum = 0;
    for(let i = 0; i < cart.length; i++){
      let price = Number(cart[i].item.preco);
      let p = price;
      sum += p;
    }
    setCartPrice(sum);
  })

  useEffect(() => {
    const storedCart = getCartFromLocalStorage();
    setCart(storedCart);
  }, []);

  return {
    cart,
    cartPrice,
    addItemToCart,
    removeItemFromCart,
    clearCart,
    getCartFromLocalStorage,
    getCartPrice,
  };

};

export default useCart;