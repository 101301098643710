import React, { useEffect, useState } from 'react';
import "./style.css";
import {BottomNavigation, Button, Header, Modal} from '../../components';
import Api from '../../Api';
import useAddress from '../../globals/useAddress';
import Utils from '../../Utils';
import { ToastContainer } from 'react-toastify';
import InputMask from 'react-input-mask';

export default () => {

  const {addressList, addAddress, getAddressesFromLocalStorage, removeAddress} = useAddress();

  const [cardappyo, setCardappyo] = useState(null);
  const [empresa, setEmpresa] = useState(null);
  const [franquia, setFranquia] = useState(null);
  const [modalOpenAddress, setOpenModalAddress] = useState(false);
  const [cep, setCep] = useState("");
  const [address, setAddress] = useState(null);
  const [addressNumber, setAddressNumber] = useState(null);
  const [addressComplement, setAddressComplement] = useState(null);

  useEffect(() => {
    const fetchCardappyo = async () => {
      let responseCardappyo = await Api.getCardappyo({keybind: localStorage.getItem("CARDAPPYO_CURRENT_KEYBIND")});
      setCardappyo(responseCardappyo?.data?.data);
      let responseEmpresa = await Api.getEmpresa({id_empresa: responseCardappyo?.data?.data?.id_empresa});
      setEmpresa(responseEmpresa?.data?.data);
      let responseFilial = await Api.getFilial({id_empresa: responseCardappyo?.data?.data?.id_empresa, id_filial: localStorage.getItem("CARDAPPYO_CURRENT_FILIAL")});
      setFranquia(responseFilial?.data?.data);
    }
    fetchCardappyo();
  }, [])

  const closeModalAddress = () => {
    setOpenModalAddress(false);
  };

  const handleAddressModdalConfirm = () => {

    if(!Utils.validCEP(cep)){
      Utils.notify("error", "Digite um CEP válido.");
      return;
    }

    if(address?.cep?.trim().length <= 0){
      Utils.notify("error", "Digite seu CEP.");
      return;
    }

    if(address?.logradouro?.trim().length <= 0){
      Utils.notify("error", "Digite seu logradouro.");
      return;
    }

    if(address?.bairro?.trim().length <= 0){
      Utils.notify("error", "Digite seu bairro.");
      return;
    }

    if(!addressNumber){
      Utils.notify("error", "Digite o numero.");
      return;
    }

    let lastId = Utils.makeid(9);

    addAddress({
      ...address,
      id: lastId,
      numero: addressNumber,
      complemento: addressComplement
    })

    Utils.notify("success", "Endereço salvo com sucesso.");
    setOpenModalAddress(false);
  }

  const handleSetCep = (e) => {
    setCep(e.target.value);

    if(!Utils.validCEP(e.target.value)){
      return;
    }

    fetch(`https://viacep.com.br/ws/${e.target.value}/json/`)
    .then(response => response.json())
    .then(data => {
      setAddress(data);
    })
    .catch(error => {
      //console.error('Erro ao verificar o CEP:', error);
    });
  }

  const handleChangeLogradouro = (e) => {
    const logradouro = e.target.value;
    setAddress({
      ...address,
      logradouro: logradouro, 
    });
  }

  const handleChangeBairro = (e) => {
    const bairro = e.target.value;
    setAddress({
      ...address,
      bairro: bairro, 
    });
  }

  return (
    <>
      <ToastContainer style={{zIndex: '999999999'}}/>
      <Modal 
        open={modalOpenAddress} 
        onClose={closeModalAddress} 
        title={`Adicionar endereço`}
        buttonConfirmBackground={empresa?.cor_primaria}
        buttonCancelText={"Cancelar"}
        buttonConfirmText={"Adicionar"}
        onConfirm={handleAddressModdalConfirm}
        buttonConfirmIcon={<i></i>}
        >
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px'}}>
          <InputMask className='input cep' value={cep} onChange={handleSetCep} mask={"99999-999"} placeholder={"Digite seu CEP"} />
        </div>
        {address ? (
          <>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px'}}>
              <input className='input' placeholder={'Digite seu logradouro'} value={address?.logradouro || ''} onChange={handleChangeLogradouro}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px'}}>
              <input className='input' placeholder={'Digite seu bairro'} value={address?.bairro || ''} onChange={handleChangeBairro}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px'}}>
              <input className='input' type={"number"} placeholder={'Numero'} value={addressNumber} onChange={e => setAddressNumber(e.target.value)}/>&nbsp;&nbsp;
              <input className='input' type={"text"} placeholder={'Complemento'} value={addressComplement} onChange={e => setAddressComplement(e.target.value)}/>
            </div>
          </>
        ) : (null)}
      </Modal>
      <Header goBack={true} title={"Meus Endereços"} cardappyo={cardappyo} franquia={franquia} empresa={empresa}/>
      <div style={{marginLeft: '16px', marginRight: '16px'}}>
        {getAddressesFromLocalStorage()?.length <= 0 ? (
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 'calc(100vh - 100px)'}}>
            <div>
              <h4 style={{color: '#4b4b4b', marginTop: '20px', marginBottom: '10px', textAlign: 'center'}}>Nenhum endereço cadastrado!</h4>
              <div style={{width: '100%', height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img src={"no_address.svg"} style={{width: '300px'}}/>
              </div>
              <Button background={empresa?.cor_primaria} width={"100%"} outline={true} onClick={() => {setOpenModalAddress(true)}}>+Adicionar Endereço</Button>
            </div>
          </div>
        ) : (
          <>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px'}}>
              <h4 style={{color: '#4b4b4b'}}>Endereços ({getAddressesFromLocalStorage()?.length})</h4>
              <Button background={empresa?.cor_primaria} outline={true} onClick={() => {setOpenModalAddress(true)}}>+Adicionar</Button>
            </div>
            {getAddressesFromLocalStorage()?.map((address, index) => (
              <div key={index} className={"address"}>
                <i class="fa fa-map-marker" style={{fontSize: '18pt'}}></i>
                &nbsp;&nbsp;<label style={{fontSize: '8pt', color: '#4b4b4b'}}>{address?.cep} - {address.numero}{address?.complemento || ''}, {address?.logradouro}, {address?.bairro}</label>&nbsp;&nbsp;
                <Button background={'red'} outline={true} onClick={() => {removeAddress(address?.id)}}><i class="fa fa-trash"></i></Button>
              </div>
            ))}
            <br/>
            <br/>
            <br/>
          </>
        )}
      </div>
      <BottomNavigation activeColor={empresa?.cor_primaria} activeTab={1} />
    </>
  )
}