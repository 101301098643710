import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes, useParams, useNavigate } from 'react-router-dom';

import {
  Cardappyo, 
  Cart,
  Pedidos,
  PageNotFound,
  Enderecos,
  Admin,
  AdminCardapio,
  AdminProdutos,
  AdminCupons,
  AdminMesas,
  AdminGarcons,
  AdminCozinha,
  AdminEntregadores,
  AdminArtista,
  AdminPefil,
  AdminConfig,
  Login,
  Register,
  Landing,
  Mesa,
  Pessoas,
  Comanda,
  CartMesa
} from "./pages";

import {MainContext} from './helpers/MainContext';
import Api from './Api';
import AdminRequestAudioEffectModal from './components/AdminRequestAudioEffectModal';

function App () {

  const [audio, setAudio] = useState(new Audio('/sineta.mp3'));
  const [ready, setReady] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [faturas, setFaturas] = useState([]);
  const [faturaEmAberto, setFaturaEmAberto] = useState(null);

  const [openModalRequestAudioEffect, setOpenModalRequestAudioEffect] = useState(() => {
    const currentPath = window.location.pathname;
    let pages = ["/admin", "/admin/cardapio", "/admin/produtos", "/admin/cupons", "/admin/mesas", "/admin/garcons", "/admin/cozinha", "/admin/entregadores", "/admin/artista"]
    if(pages.includes(currentPath)){
      return true;
    }
    return false;
  });

  const reloadPageAfterHours = () => {
    const tempo = 8 * 60 * 60 * 1000;
    function recarregarPagina() {
      window.location.reload();
    }
    setTimeout(recarregarPagina, tempo);
  }

  useEffect(() => {

    const isAdminPath = window.location.pathname.includes('/admin');

    const fetchAuth = async () => {
      try {
        const responseAuth = await Api.admin.getAuth();
        if (responseAuth?.data?.data?.success) {
          let responseUser = await Api.admin.getUser();
          setUser(responseUser?.data?.data);

          if(isAdminPath){
            let responseFaturaEmAberto = await Api.admin.getFaturaEmAberto({id_empresa: responseUser?.data?.data?.id_empresa})
            let responseFaturas = await Api.admin.getFaturas({id_empresa: responseUser?.data?.data?.id_empresa});
            setFaturaEmAberto(responseFaturaEmAberto?.data?.data[0] || null);
            setFaturas(responseFaturas?.data?.data);
          }

          setAuthenticated(true);
        } else {
          setAuthenticated(false);
        }
      } catch (error) {
        console.error("Erro ao verificar autenticação:", error);
        setAuthenticated(false);
      } finally {
        setReady(true);
      }
    };

    fetchAuth();
    reloadPageAfterHours();
    
  }, []);

  useEffect(() => {
    if (ready && authenticated) {
      const currentPath = window.location.pathname;
      const isLoginPage = currentPath === "/login";
      const isRegisterPage = currentPath === "/register";
      if (isLoginPage || isRegisterPage) {
        window.location = window.location.origin+"/admin";
      }
    }
  }, [ready, authenticated]);

  const playAudio = () => {
      audio.loop = true;
      audio.volume = 0;
      audio.play().catch(error => {
          console.error('Erro ao reproduzir áudio:', error);
      });
  }

  const muteAudio = () => {
      let timeout = setTimeout(() => {
          audio.volume = 0;
          clearTimeout(timeout);
      }, 10000)
  }

  const logout = () => {
    localStorage.removeItem("CARDAPPYO_ACCESS_TOKEN");
  }

  return (
    <MainContext.Provider value={{ authenticated, setAuthenticated, user, audio, playAudio, muteAudio, logout, faturas, faturaEmAberto }}>
      <AdminRequestAudioEffectModal open={openModalRequestAudioEffect} onClose={() => {setOpenModalRequestAudioEffect(false)}} />
      <Router>
        {ready ? (
            <Routes>
              <Route path="/:filial/:keybind" exact element={<Cardappyo/>} />
              <Route path="/:filial/:keybind/:id_mesa" exact element={<Mesa/>}/>
              <Route path="/cart" exact element={<Cart/>} />
              <Route path="/cart-mesa" exact element={<CartMesa/>} />
              <Route path="/pedidos" exact element={<Pedidos/>} />
              <Route path="/enderecos" exact element={<Enderecos/>} />
              <Route path="/pessoas" exact element={<Pessoas/>} />
              <Route path="/comanda" exact element={<Comanda/>} />
              <Route
                path="/admin"
                element={
                  authenticated ? <Admin /> : <Navigate to="/login" replace />
                }
              />
              <Route
                path="/admin/cardapio"
                element={
                  authenticated ? <AdminCardapio /> : <Navigate to="/login" replace />
                }
              />
              <Route
                path="/admin/produtos"
                element={
                  authenticated ? <AdminProdutos /> : <Navigate to="/login" replace />
                }
              />
              <Route
                path="/admin/cupons"
                element={
                  authenticated ? <AdminCupons /> : <Navigate to="/login" replace />
                }
              />
              <Route
                path="/admin/mesas"
                element={
                  authenticated ? <AdminMesas /> : <Navigate to="/login" replace />
                }
              />
              <Route
                path="/admin/garcons"
                element={
                  authenticated ? <AdminGarcons /> : <Navigate to="/login" replace />
                }
              />
              <Route
                path="/admin/cozinha"
                element={
                  authenticated ? <AdminCozinha /> : <Navigate to="/login" replace />
                }
              />
              <Route
                path="/admin/entregadores"
                element={
                  authenticated ? <AdminEntregadores /> : <Navigate to="/login" replace />
                }
              />
              <Route
                path="/admin/artista"
                element={
                  authenticated ? <AdminArtista /> : <Navigate to="/login" replace />
                }
              />
              <Route
                path="/admin/perfil"
                element={
                  authenticated ? <AdminPefil /> : <Navigate to="/login" replace />
                }
              />
              <Route
                path="/admin/config"
                element={
                  authenticated ? <AdminConfig /> : <Navigate to="/login" replace />
                }
              />
              <Route path='/login' exact element={<Login/>}></Route>
              <Route path='/register' exact element={<Register/>}></Route>
              <Route path='/register/:code_affiliate' exact element={<Register/>}></Route>
              <Route path='/' exact element={<Landing/>}></Route>
              <Route path="*" element={<PageNotFound/>} />
            </Routes>
          ) : (null)}
      </Router>
    </MainContext.Provider>
  );

}

export default App;
