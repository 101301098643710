import React, { useContext, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Alert, Container } from '@mui/material';
import Button from '../Button';
import PixIcon from '@mui/icons-material/Pix';
import { MainContext } from '../../helpers/MainContext';
import isMobile from 'is-mobile';
import Api from '../../Api';
import { Link } from 'react-router-dom';

export default ({fatura, daysLeft, show, spacing}) => {

    const {user} = useContext(MainContext);

    const theme = useTheme();

    const [status, setStatus] = useState(null);

    useEffect(() => {

        const fetchPixReceived = async () => {
            if(fatura != null){
                let response = await Api.admin.getFaturaStatus({id_fatura: fatura?.id_fatura});
                setStatus(response?.data?.data);
            }
        }

        fetchPixReceived();

        let intervalId = setInterval(fetchPixReceived, 3000);

        return () => clearInterval(intervalId);

    }, [])

    const ContainerPix = () => (
        show ? (
            status == "A" ? (
                <div>
                    {daysLeft <= 0 ? (
                        <Alert severity="error" sx={{mb: 2}}>Pague a mensalidade para continuar usando o sistema.</Alert>
                    ) : (null)}
                    <Card>
                        <div style={{display: 'flex', alignItems: 'center', paddingLeft: '10px', paddingTop: '20px'}}>
                            <h3>{user?.nome}, sua fatura chegou 😀!</h3>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', padding: '10px', width: '100%'}}>
                            {daysLeft <= 0 ? (
                                <Alert severity="info" sx={{width: '100%'}}>Pague a fatura para desbloquear sua conta.</Alert>
                            ) : (
                                <Alert severity="info" sx={{width: '100%'}}>Sua fatura expira em {daysLeft} dia(s), pague sua fatura e evite o bloqueio.</Alert>
                            )}
                        </div>
                        <div style={{ display: isMobile() ? 'block' : 'flex', alignItems: 'center', border: 'dashed 1px gray', borderRadius: '8px', marginLeft: '10px', marginRight: '10px' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: 181 }}
                                image={fatura?.qrcode_pix}
                                alt="Pague no PIX"
                            />
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <CardContent>
                                    <Typography component="div" variant="h6">
                                        Fatura Mensal
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                        Valor: <b style={{color: 'green'}}>{(Number(fatura?.total)/100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                    </Typography>
                                    <Typography variant="subtitle2" color="text.secondary" component="div" sx={{mb: 1}}>
                                        Recebedor: <b>Tekhno Develop</b>
                                    </Typography>
                                    <Button background='green'>
                                        <PixIcon/>&nbsp;
                                        Copiar Chave
                                    </Button>
                                </CardContent>
                            </Box>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '20px'}}>
                            <img src={"../selo1.png"} width={"100px"}/>&nbsp;&nbsp;&nbsp;&nbsp;
                            <img src={"../selo2.webp"} width={"130px"}/>
                        </div>
                    </Card>
                    {spacing ? (<><br/><br/><br/><br/></>) : (null)}
                </div>
            ) : status == "B" ? (
                <div>
                    <Alert severity="success" sx={{mb: 2}}>
                        Fatura paga com sucesso!&nbsp;
                        <Link onClick={() => { window.location.reload() }}>Voltar ao início</Link>
                    </Alert>
                </div>
            ) : (null)
        ) : (null)
    )

    return (<ContainerPix/>)

}
