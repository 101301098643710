import React, { useState } from 'react';
import "./style.css";
import { Box, Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import StoreIcon from '@mui/icons-material/Store';
import DoneIcon from '@mui/icons-material/Done';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Utils from '../../Utils';
import Api from '../../Api';
import AdminAcordionPedidoDetalhes from '../AdminAcordionPedidoDetalhes';
import { Br, Cut, Line, Printer, Text, Row, render } from 'react-thermal-printer';

const Recibo = ({pedido, itens}) => (
    <Printer type="epson" width={42} characterSet="korea">
        <Text size={{ width: 2, height: 2 }}>9,500원</Text>
        <Text bold={true}>Recipo de Pedido</Text>
        <Br />
        <Line />
        <Row left="Método de Pagamento" right="Cartão de Débito" />
        <Row left="Número do Cartão" right="123456**********" />
        <Row left="Parcelamento" right="À vista" />
        <Row left="Valor do Pagamento" right="9,500" />
        <Row left="Valor do Imposto" right="863" />
        <Row left="Valor Total" right="8,637" />
        <Line />
        <Row left="Chá de Milho Delicioso X 2" right="11,000" />
        <Text>Opção1(500)/Opção2/Memo</Text>
        <Row left="(-) Desconto" right="- 500" />
        <Br />
        <Line />
        <Row left="Total" right="9,500" />
        <Row left="(-) Desconto 2%" right="- 1,000" />
        <Line />
        <Row left="Representante" right="Kim Rep" />
        <Row left="Número de Registro Comercial" right="000-00-00000" />
        <Row left="Número do Representante" right="0000-0000" />
        <Row left="Endereço" right="Algum Lugar, Alguma Rua, Algum Número" />
        <Line />
        <Br />
        <Text align="center">Wifi: algum-wifi / Senha: 123123</Text>
        <Cut />
  </Printer>
  );

export default ({pedido, itens, fetchPedidos}) => {

    const getIcon = () => {
        switch (pedido?.tipo) {
          case 'Mesa':
            return <TableRestaurantIcon/>;
          case 'Entrega':
            return <DeliveryDiningIcon />;
          case 'Retirada':
            return <StoreIcon />;
          default:
            return null;
        }
    };

    const getTitle = () => {
        switch (pedido?.tipo) {
          case 'Mesa':
            return "Pedido Mesa-1";
          case 'Entrega':
            return "Pedido de Delivery";
          case 'Retirada':
            return "Pedido para Retirada";
          default:
            return null;
        }
    };

    const getButtonTitle = () => {
        switch (pedido?.status) {
          case 'A':
            return {title: "Aceitar", icon : <DoneIcon/>, status: "C" };
          case 'C':
            return {title: "Avançar", icon : <ArrowForwardIcon/>, status: "D" };
          case 'D':
            return {title: "Entregue", icon: <DoneIcon/>, status: "E"};
          default:
            return null;
        }
    };

    const handleSetStatusPedido = async (status) => {
        console.log(status)
        switch(status){
            case "R":
                Utils.notify("success", "Pedido recusado!");
                break;
            case "C":
                Utils.notify("success", "Pedido aceito!");
                const dados = await render(Recibo({pedidp: pedido, itens: itens}));
                printContent(dados);
                break;
            case "D":
                Utils.notify("success", "Pedido pronto!");
                break;
            case "E":
                Utils.notify("success", "Pedido Finalizado!");
                break;
        }
        await Api.admin.setStatusPedido({
            id_pedido: pedido?.id_pedido,
            status: status,
        })
        fetchPedidos();
    }

    const printContent = (content) => {
        const printWindow = window.open('', '_blank');
        printWindow.document.write(content);
        printWindow.document.close();
        printWindow.print();
    };

    return (
        <Card variant="outlined" className='card-item-pedido'>
            <React.Fragment>
                <CardContent>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{background: "#f5f5f5", display: 'flex', alignItems: 'center'}}>
                            {getIcon()}
                        </div>&nbsp;
                        <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="black" gutterBottom>
                            {getTitle()}
                        </Typography>
                        </div>
                        <div>
                        <div style={{display: 'flex', alignItems:'center', justifyContent: 'center', background: '#d3eeff', borderRadius: '4px', padding: '4px'}}>
                            <ScheduleIcon style={{fontSize: '12pt', color: '#0067ac'}} />&nbsp;
                            <b style={{color: '#0067ac', fontSize: '12pt'}}>{Utils.formatHour(pedido?.ts_registro?.split(" ")[0])}</b>
                        </div>
                        </div>
                    </div>

                    <Typography sx={{ mb: 0, mt: 1.5 }} color="text.secondary">
                        ID: <b>#{pedido?.id_ref}</b>
                    </Typography>
                    <Typography sx={{ mb: 0, mt: 0 }} color="text.secondary">
                        Solicitante: <b>{pedido?.nome}</b>
                    </Typography>
                    <Typography sx={{ mb: 0, mt: 0 }} style={{fontSize: '8pt'}} color="text.secondary">
                        <b>{Utils.formatSmallDate(pedido?.ts_registro?.split(" ")[0])}</b>
                    </Typography>

                    {/*<Typography variant="body2" sx={{mb: 2}} color="text.secondary">
                        {'Observações: Tirar a cebola por favor'}
                    </Typography>*/}
                    <Box sx={{mt: 2}}/>

                    <AdminAcordionPedidoDetalhes pedido={pedido} itens={itens}/>

                </CardContent>
                <CardActions>
                    {pedido?.status == "A" ? (
                        <Button style={{width: '100%'}} variant="outlined" onClick={() => {handleSetStatusPedido("R")}}>Recusar <HighlightOffIcon/></Button>
                    ) : (null)}
                    <Button style={{width: '100%'}} variant="contained" onClick={() => {handleSetStatusPedido(getButtonTitle().status)}}>{getButtonTitle().title} {getButtonTitle().icon}</Button>
                </CardActions>
            </React.Fragment>
        </Card>
    )
}
