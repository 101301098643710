import * as React from 'react';
import './style.css'
import AdminMainContainer from '../../components/AdminMainContainer';
import { AdminNotificationManager } from '../../components';
import { Alert, AlertTitle, Button, Container, FormControl, InputAdornment, InputLabel, MenuItem, Paper, Select, Switch, TextField, Typography } from '@mui/material';
import isMobile from 'is-mobile';
import SearchIcon from '@mui/icons-material/Search';

import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Api from '../../Api';
import { MainContext } from '../../helpers/MainContext';
import Utils from '../../Utils';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import UploadIcon from '@mui/icons-material/Upload';
import CurrencyInput from '../../components/CurrencyInput';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const ProductDialog = ({onDispose}) => {

  const fileInputRef = React.useRef(null);
  const fileInputRefAdicional = React.useRef(null);

  const [open, setOpen] = React.useState(true);
  const [imageSrc, setImageSrc] = React.useState("");
  const [imageSrcAdicional, setImageSrcAdicional] = React.useState("");
  const [preco, setPreco] = React.useState("");
  const [precoPromocional, setPrecoPromocional] = React.useState("");

  const [adicionalPreco, setAdicionalPreco] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onDispose(false);
  };

  const handleLabelClick = () => {
    fileInputRef.current.click();
  };

  const handleLabelAdicionalClick = () => {
    fileInputRefAdicional.current.click();
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result); // Define a URL da imagem no estado
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileAdicionalChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrcAdicional(reader.result); // Define a URL da imagem no estado
      };
      reader.readAsDataURL(file);
    }
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Salvar Produto"}
        </DialogTitle>
        <DialogContent>
          <div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
            <img style={{width: '100px', height: '100px', objectFit: 'cover', border: 'dashed #ddd 1px', borderRadius: '4px', cursor: 'pointer'}} src={imageSrc || "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"}/>&nbsp;&nbsp;
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: 'none' }}
              ref={fileInputRef}
              id="upload-button"
            />
            <label htmlFor="upload-button" onClick={handleLabelClick}>
              <Button variant="elevated" endIcon={<UploadIcon />}>
                Imagem
              </Button>
            </label>
          </div>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <TextField
              name="nome"
              fullWidth
              id="nome"
              label="Nome"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
              name="descricao"
              fullWidth
              multiline
              rows={3}
              id="descricao"
              label="Descrição"
              />
            </Grid>
            <Grid container spacing={1} sx={{mt: 1, mb: 1}}>
              <Grid item xs={12} sm={6}>
                <div style={{paddingRight: '4px'}}>
                  <label style={{marginLeft: '8px'}}>Preço (R$)</label>
                  <CurrencyInput value={preco} style={{width: '100%', marginTop: '5px', marginLeft: '8px'}} onChange={(e) => {setPreco(e.target.value)}} />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{paddingRight: '4px'}}>
                  <label style={{marginLeft: '8px'}}>Preço Promocional (R$)</label>
                  <CurrencyInput value={precoPromocional} style={{width: '100%', marginTop: '5px', marginLeft: isMobile() ? '8px' : '4px'}} onChange={(e) => {setPrecoPromocional(e.target.value)}} />
                </div>
              </Grid>
            </Grid>
            <Grid item xs={9} sm={9} sx={{mb: 1}}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                <Select
                  label="Categoria"
                >
                  <MenuItem value={10}>Item</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3} sm={3} sx={{mb: 1}}>
              <Button variant='outlined' style={{height: 57}}>+{isMobile() ? '' : 'Categoria'}</Button>
            </Grid>
            <Grid item xs={6} sm={6} sx={{mb: 1}}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Qtd Pessoas</InputLabel>
                <Select
                  label="Qtd Pessoas"
                >
                  {[1,2,3,4,5,6,7,8,9,10].map((item, index) => (
                    <MenuItem value={item}>Serve {item} pessoa{`${item > 1 ? '(s)' : ''}`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Tempo de Preparo</InputLabel>
                <Select
                  label="Tempo de Preparo"
                >
                  <MenuItem value={"1-5"}>1-5 minutos</MenuItem>
                  <MenuItem value={"5-10"}>5-10 minutos</MenuItem>
                  <MenuItem value={"10-20"}>10-20 minutos</MenuItem>
                  <MenuItem value={"20-40"}>20-40 minutos</MenuItem>
                  <MenuItem value={"40-60"}>40-60 minutos</MenuItem>
                  <MenuItem value={"60-80"}>60-80 minutos</MenuItem>
                  <MenuItem value={"80-100"}>80-100 minutos</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} sx={{mt: 2}}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Adicionais</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  <Grid  item xs={12} sm={2}>
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileAdicionalChange}
                        style={{ display: 'none' }}
                        ref={fileInputRefAdicional}
                        id="upload-adicional-button"
                      />
                      {isMobile() ? (
                        <>
                          <img style={{width: '57px', height: '57px', objectFit: 'cover', border: 'dashed #ddd 1px', borderRadius: '4px', cursor: 'pointer'}} src={imageSrcAdicional || "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"}/>&nbsp;&nbsp;
                          <label htmlFor="upload-adicional-button" onClick={handleLabelAdicionalClick}>
                            <Button variant="elevated" endIcon={<UploadIcon />}>
                              Imagem
                            </Button>
                          </label>
                        </>
                      ) : (
                        <label htmlFor="upload-adicional-button" onClick={handleLabelAdicionalClick}>
                          <Button>
                            <img style={{width: '57px', height: '45px', objectFit: 'cover', border: 'dashed #ddd 1px', borderRadius: '4px', cursor: 'pointer'}} src={imageSrcAdicional || "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"}/>
                          </Button>
                        </label>
                      )} 
                    
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="nome"
                      fullWidth
                      id="nome"
                      label="Nome"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} sx={{marginTop: isMobile() ? '0px' : '-25px'}}>
                    <div style={{}}>
                      <label style={{}}>Preço (R$)</label>
                      <CurrencyInput value={adicionalPreco} style={{width: '100%'}} onChange={(e) => {setAdicionalPreco(e.target.value)}} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="limite"
                      fullWidth
                      id="limite"
                      label="Nº Limite"
                      type='number'
                    />
                  </Grid>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', marginTop: '10px'}}>
                    <Button variant='outlined' style={{height: 57}}>+ adicionar</Button>
                  </div>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button variant='contained' onClick={() => {}} autoFocus>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const ProductCard = ({produto, ativo}) => {

  const [checked, setChecked] = React.useState(ativo)

  const handleSwitch = async (e) => {
    setChecked(e.target.checked);
    await Api.admin.produtoSwitchAtivo({
      id_item: produto?.id_item,
      checked: e.target.checked ? 1 : 0
    });
    Utils.notify("success", `Produto ${e.target.checked ? 'ativado.' : 'desativado.'}`)
  }

  return (
    <Grid item xs={12} md={3}>
      <Demo>
        <List dense={true}>
          <ListItem
            secondaryAction={
              <>
                <Switch checked={checked} onChange={handleSwitch}/>
                <IconButton edge="end" aria-label="delete">
                  <EditIcon />
                </IconButton>&nbsp;&nbsp;
                <IconButton edge="end" aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </>
            }
          >
            <ListItemAvatar>
              <Avatar src={produto?.uri_imagem} />
            </ListItemAvatar>
            <ListItemText
              primary={produto?.nome}
              secondary={produto?.nome_categoria}
            />
          </ListItem>
        </List>
      </Demo>
    </Grid>
  )
}

export default () => {

  const {user} = React.useContext(MainContext);

  const [produtos, setProdutos] = React.useState([]);
  const [produtosFiltered, setProdutosFiltered] = React.useState([]);
  const [search, setSearch] = React.useState("");

  const [modalProductOppened, setModalProductOppened] = React.useState(false);

  React.useEffect(() => {
    fetchProdutos();
  }, [])

  const fetchProdutos = async () => {
    setProdutos([]);
    let response = await Api.admin.getProdutos({
      id_empresa: user?.id_empresa
    })
    setProdutos(response?.data?.data)
    setProdutosFiltered(response?.data?.data);
  }

  const handleClickCriarProduto = async () => {
    setModalProductOppened(true);
  } 

  const handleSearch = async (e) => {
    setSearch(e.target.value);
   
    if (e.target.value && e.target.value.trim().length > 0) {
      let arr =  produtos.filter(item => item.nome.toLowerCase().includes(e.target.value.toLowerCase()));
      setProdutosFiltered(arr);
    } else {
      setProdutosFiltered(produtos);
    }

    if(e.target.value?.trim()?.length <= 0){
      fetchProdutos();
    }
  }

  return (
    <AdminMainContainer menuIndex={2}>
        <AdminNotificationManager callback={() => {}}/>
        {modalProductOppened ? (<ProductDialog onDispose={setModalProductOppened}/>) : (null)}
        <Container maxWidth="xl" sx={{ mt: 3, mb: 2, }} style={{paddingLeft: isMobile() ? '24px' : '0px'}}>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: isMobile() ? '10px' : '20px'}}>
            <Typography variant='h6'>Produtos({produtos?.length})</Typography>

            {!isMobile() ? (
              <TextField
                label="Pesquisar produto"
                type='search'
                style={{width: '70%'}}
                value={search}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
            ) : (null)}
            
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Button variant='outlined' size={isMobile() ? 'small' : 'medium'} onClick={handleClickCriarProduto}>+Produto</Button>
            </div>
          </div>
          <div>
            {isMobile() ? (
              <TextField
                label="Pesquisar produto"
                type='search'
                style={{width: '100%', marginBottom: '15px'}}
                value={search}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
            ) : (null)}

            <div style={{height: isMobile() ? 'calc(100vh - 280px)' : 'calc(100vh - 155px)', overflow: 'auto'}}>
            
              <Grid container spacing={1}>
                {produtos?.length > 0 && produtosFiltered.map((produto, index) => (
                  <ProductCard key={produto.id_item} ativo={produto?.ativo == 1} produto={produto} />
                ))}
              </Grid>
              
              {produtosFiltered.length <= 0 ? (
                <Alert severity="info">
                  Nenhum produto encontrado!
                </Alert>
              ) : (null)}

            </div>

          </div>
        </Container>
    </AdminMainContainer>
  );
}