import React from 'react';
import "./style.css";

const URL_TO_RENDER = "https://tk-cardappyo-landing.vercel.app";

export default () => {
  return (
    <div style={{position: 'absolute', height: '100%', width: "100%", overflow: 'hidden'}}>
      <iframe width={"100%"} height={"100%"} src={URL_TO_RENDER}/>
    </div>
  )
}
