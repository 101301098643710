import React, { useEffect, useState } from 'react';
import "./style.css";
import Api from '../../Api';
import AdminAcordionPedidoDetalhes from '../AdminAcordionPedidoDetalhes';
import { Alert, Step, StepLabel, Stepper } from '@mui/material';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Check from '@mui/icons-material/Check';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: 'green',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: 'green',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
}));

const QontoConnectorRed = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'red',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'red',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepRedIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: 'red',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: 'red',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: 'green',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: 'green',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
}));
  
function QontoStepIcon(props) {
    const { active, completed, className } = props;
  
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
}

function QontoStepIconRed(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepRedIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <ReportProblemIcon className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepRedIconRoot>
  );
}


export default ({pedido, empresa, franquia}) => {

    const [itens, setItens] = useState();
    const [status, setStatus] = useState(null);

    useEffect(() => {

        const fetchItens = async () => {
            let ids_pedidos = [];
            ids_pedidos.push(pedido?.id_pedido);
            let responsePedidoItens = await Api.admin.getPedidoItens({
                ids_pedidos: ids_pedidos
            })
            setItens(responsePedidoItens?.data?.data);
        }

        const fetchStatus = async () => {
            let responseStatus = await Api.getPedidoStatus({id_pedido: pedido?.id_pedido});
            setStatus(responseStatus?.data?.data);
        }

        fetchItens();
        fetchStatus();

        const loop = async () => {
            fetchStatus();
        }

        const intervalId = setInterval(loop, 5000);
    
        return () => clearInterval(intervalId);


    }, [])
      
    const steps = [
        'Aguardando confirmação',
        'Pedido em produção',
        'Saiu para entrega',
    ];

    const convertStatusPedidoToStep = (status) => {
        switch(status){
            case "A":
                return 0;
            case "B":
                return 1;
            case "C":
                return 1;
            case "D":
                return 2;
            case "E":
                return 3;
            case "R":
                return 5;
        }
    }

    return (
        <>
            <div className={ (pedido?.status != "R" && pedido?.status != "E") ? 'pedido-container' : 'pedido-container-opacity'}>
                <div className='pedido-header'>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{marginRight: '10px'}}>
                            <img src={empresa?.logo_pequena} style={{width: '50px', height: '50px', objectFit: 'cover', borderRadius: '50%'}} />
                        </div>
                        <div>
                            <h5 style={{color: '#4b4b4b', fontSize: '12pt'}}>{franquia?.nome_fantasia}</h5>
                            {(pedido?.status != "R") ? (
                              <b style={{fontSize: '10pt', color: '#4b4b4b'}}>Previsão: {pedido?.estimativa_entrega}</b>
                            ) : (null) }
                        </div>
                    </div>
                </div>
                <div className='pedido-body'>
                    {pedido?.status != "R" ? (
                      <Stepper alternativeLabel sx={{mt: 2, mb: 3}} activeStep={convertStatusPedidoToStep(status)} connector={<QontoConnector />}>
                        {steps.map((label) => (
                          <Step key={label}>
                              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    ) : (
                      <>
                        <Stepper alternativeLabel sx={{mt: 2, mb: 3}} activeStep={3} connector={<QontoConnectorRed />}>
                          {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={QontoStepIconRed}></StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                        <Alert variant="filled" severity="error" sx={{mb: 2}}>
                          Pedido Cancelado
                        </Alert>
                      </>
                    )}
                    <AdminAcordionPedidoDetalhes pedido={pedido} itens={itens} hideAddressCard={true}/>
                </div>
            </div>
        </>
    )
}
