import React, { useEffect, useState } from 'react';
import "./style.css";
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
    />
    ))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));
  
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default ({itensToRender}) => {

    const [itens, setItens] = useState([]);
    const [expanded, setExpanded] = React.useState('panel1');

    useEffect(() => {
        setItens(itensToRender);
    }, [])

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const getAdicionais = (jsonStringify) => {
        try{
            let adicionais = JSON.parse(jsonStringify);
            let str = "";
            if(adicionais?.length > 0){
                str += ", com ";
                for(let i = 0; i < adicionais.length; i++){
                    str += "x"+adicionais[i]?.quantity+" "+adicionais[i]?.nome;
                    if(i < (adicionais.length - 1)){
                        if (i != (adicionais.length - 2)){
                            str += ", ";
                        }
                    }
                    if (i == (adicionais.length - 2)){
                        str += " e ";
                    }
                }
            }
            return str;
        }catch(err){
            return "";
        }
    }

    return (
        <>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{mt: 2}}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <Typography>Detalhes da mesa</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{border: 'solid 1px #ddd', borderRadius: '4px', height: '150px', overflowY: 'auto'}}>
                        {itens?.map((item, index) => (
                            <div className='pedido-item-admin'>
                                {item?.uri_imagem ? (
                                    <>
                                        <img src={item?.uri_imagem} width={20} style={{borderRadius: '4px'}}/>&nbsp;
                                    </>
                                ) : (null)}
                                <span>{item?.quantidate}x | {item?.nome}{getAdicionais(item?.adicionais)}</span>
                            </div> 
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    )
}
