import React, { useEffect, useState } from 'react';
import "./style.css";
import { Button, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import PlaceIcon from '@mui/icons-material/Place';
import NearMeIcon from '@mui/icons-material/NearMe';
import ShareIcon from '@mui/icons-material/Share';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import PaymentsIcon from '@mui/icons-material/Payments';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import StoreIcon from '@mui/icons-material/Store';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
    />
    ))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));
  
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default ({pedido, itens, hideAddressCard}) => {

    const [expanded, setExpanded] = React.useState('');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleGoMaps = () => {
        let link = `http://www.google.com/maps/place/${pedido?.latitude},${pedido?.longitude}`;
        window.open(link, "blank");
    }

    const handleShareMaps = () => {
        if (navigator.share) {
            let link = `http://www.google.com/maps/place/${pedido?.latitude},${pedido?.longitude}`;
            navigator.share({
              title: "Localização",
              text: "Localização do pedido",
              url: link,
            })
            .then(() => console.log('Sucesso ao compartilhar'))
            .catch((error) => console.log('Erro ao compartilhar', error));
        } else {
            console.log('Seu navegador nao suporta o modo de compartilhamento.');
        }
    }

    const getAdicionais = (jsonStringify) => {
        let adicionais = JSON.parse(jsonStringify);
        let str = "";
        if(adicionais?.length > 0){
            str += ", com adicionais de ";
            for(let i = 0; i < adicionais.length; i++){
                str += "x"+adicionais[i]?.quantity+" "+adicionais[i]?.nome;
                if(i < (adicionais.length - 1)){
                    if (i != (adicionais.length - 2)){
                        str += ", ";
                    }
                }
                if (i == (adicionais.length - 2)){
                    str += " e ";
                }
            }
        }
        return str;
    }

    return (
        <>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography>Detalhes do pedido</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{border: 'solid 1px #ddd', borderRadius: '4px'}}>
                        {itens?.map((item, index) => (
                            <div className='pedido-item-admin'>
                                {item?.uri_imagem ? (
                                    <>
                                        <img src={item?.uri_imagem} width={20} style={{borderRadius: '4px'}}/>&nbsp;
                                    </>
                                ) : (null)}
                                <span>{item?.quantidate}x | {item?.nome}{getAdicionais(item?.adicionais)}</span>
                            </div> 
                        ))}
                    </div>

                    <Paper sx={{mt: 2}}>
                        <div className='pedido-item-admin-totals'>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                {pedido?.tipo == "Entrega" ? (<DeliveryDiningIcon style={{fontSize: '10pt'}}/>) : (<StoreIcon style={{fontSize: '10pt'}}/>)}
                                &nbsp;<label>Serviço</label>
                            </div>
                            <div><b>{pedido?.tipo}</b></div>
                        </div>
                    </Paper>
                    
                    {pedido?.tipo != "Mesa" ? (
                        <Paper sx={{mt: 2}}>
                            <div className='pedido-item-admin-totals'>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <PaymentsIcon style={{fontSize: '10pt'}}/>&nbsp;
                                    <label>Pagamento</label>
                                </div>
                                <div><b>{pedido?.pagamento}{pedido?.troco != null ? `, troco(${Number(pedido?.troco).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})})`:""}</b></div>
                            </div>
                        </Paper>
                    ) : (null)}

                    <Paper sx={{mt: 2}}>
                        <div className='pedido-item-admin-totals'>
                            <div>Subtotal</div>
                            <div><b>{Number(pedido?.subtotal).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></div>
                        </div>
                        <div className='pedido-item-admin-totals'>
                            <div>Desconto</div>
                            <div><b>{Number(pedido?.desconto).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></div>
                        </div>
                        <div className='pedido-item-admin-totals'>
                            <div>Frete</div>
                            <div><b>{Number(pedido?.frete).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></div>
                        </div>
                        <div className='pedido-item-admin-totals'>
                            <div>Total</div>
                            <div><b>{Number(pedido?.total).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></div>
                        </div>
                    </Paper>

                    {pedido?.tipo == "Entrega" ? (
                        <Paper sx={{mt: 2}} style={{padding: '8px', display: (hideAddressCard == true) ? 'none' : 'block'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div>
                                    <PlaceIcon/>
                                </div>&nbsp;&nbsp;
                                <div style={{fontSize: '10pt'}}>
                                    {pedido?.endereco}
                                </div>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '8px'}}>
                                <Button size="small" style={{}} onClick={() => {handleShareMaps()}} variant='outlined' color="secondary"><ShareIcon/></Button>&nbsp;
                                <Button size="small" style={{}} onClick={() => {handleGoMaps()}} variant='outlined' color="secondary"><NearMeIcon/>IR</Button>
                            </div>
                        </Paper>
                    ) : (null)}
                </AccordionDetails>
            </Accordion>
        </>
    )
}
