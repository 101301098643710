import React, { useContext } from 'react';
import "./style.css";
import MuiAppBar from '@mui/material/AppBar';
import { MainContext } from '../../helpers/MainContext';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';
import Badge from '@mui/material/Badge';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Utils from '../../Utils';
import isMobile from 'is-mobile';

const drawerWidth = Utils.getDrawerWidth();

const AppBarDesktop = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}));

const AppBarMobile = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}));

export default  ({open, toggleDrawer}) => {

    const {user} = useContext(MainContext);

    return (
        isMobile() ? (
            <AppBarMobile position="fixed" style={{background: '#fff'}} open={open}>
                <Toolbar
                sx={{
                    pr: '24px',
                }}
                >
                    <IconButton
                        edge="start"
                        color="rgba(0, 0, 0, 0.54)"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                        marginRight: '9px',
                        ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="rgba(0, 0, 0, 0.54)"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        {user?.nome_fantasia} - Cardappyo
                    </Typography>
                    <IconButton color="rgba(0, 0, 0, 0.54)">
                        <Badge badgeContent={0} color="secondary">
                        <NotificationsIcon />
                        </Badge>
                    </IconButton>
                </Toolbar>
            </AppBarMobile>
        ) : (
            <AppBarDesktop position="fixed" style={{background: '#fff'}} open={open}>
                <Toolbar
                sx={{
                    pr: '24px',
                }}
                >
                    <IconButton
                        edge="start"
                        color="rgba(0, 0, 0, 0.54)"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                        marginRight: '36px',
                        ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="rgba(0, 0, 0, 0.54)"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        {user?.nome_fantasia} - Cardappyo
                    </Typography>
                    <IconButton color="rgba(0, 0, 0, 0.54)">
                        <Badge badgeContent={0} color="secondary">
                        <NotificationsIcon />
                        </Badge>
                    </IconButton>
                </Toolbar>
            </AppBarDesktop>
        )
    )
}
