import React, { useContext, useEffect, useState } from 'react';
import "./style.css";
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import GroupIcon from '@mui/icons-material/Group';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import PersonIcon from '@mui/icons-material/Person';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import SettingsIcon from '@mui/icons-material/Settings';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import LogoutIcon from '@mui/icons-material/Logout';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Utils from '../../Utils';
import { useNavigate } from 'react-router-dom';
import { MainContext } from '../../helpers/MainContext';
import { Badge } from '@mui/material';
import isMobile from 'is-mobile';

const drawerWidth = Utils.getDrawerWidth();

const cssListActive = () => {
    return {background: `${Utils.getThemeDefault()?.palette?.primaryLight?.main}`, color: `${Utils.getThemeDefault()?.palette?.primary?.main}`, borderLeft: `solid 5px ${Utils.getThemeDefault()?.palette?.primary?.main}`};
}

const cssIconActive = () => {
    return {color: `${Utils.getThemeDefault()?.palette?.primary?.main}`};
}

const menuItems = [
    {
        index: 0,
        icon: <ReceiptLongIcon />,
        label: "Pedidos",
        to: "/admin",
    },
    {
        index: 1,
        icon: <MenuBookIcon />,
        label: "Cardápio",
        to: "/admin/cardapio",
    },
    {
        index: 2,
        icon: <Inventory2Icon />,
        label: "Produtos",
        to: "/admin/produtos",
    },
    {
        index: 3,
        icon: <LocalActivityIcon />,
        label: "Cupons",
        to: "/admin/cupons",
    },
    {
        index: 4,
        icon: <TableRestaurantIcon />,
        label: "Mesas",
        to: "/admin/mesas",
    },
    {
        index: 5,
        icon: <GroupIcon />,
        label: "Garçons",
        to: "/admin/garcons",
    },
    {
        index: 6,
        icon: <RestaurantIcon />,
        label: "Cozinha",
        to: "/admin/cozinha",
    },
    {
        index: 7,
        icon: <SportsMotorsportsIcon />,
        label: "Entregadores",
        to: "/admin/entregadores",
    },
    {
        index: 8,
        icon: <AudiotrackIcon />,
        label: "Artista",
        to: "/admin/artista",
    },
];

const submenuItems = [
  {
    index: 9,
    icon: <PersonIcon/>,
    label: "Perfil",
    to: "/admin/perfil"
  },
  {
    index: 10,
    icon: <SettingsIcon/>,
    label: "Configurações",
    to: "/admin/config"
  }
]

const isModuleAccess = (name, modulos) => {
  let modulo = modulos?.filter(m => {return m?.nome == name})[0];
  return (Number(modulo?.ativo) == 1);
}

export const mainListItems = (index, navigate, modulos, open) => (
    <React.Fragment>
        {menuItems.map((item, i) => (
            isModuleAccess(item?.label, modulos) ? (
              <ListItemButton key={i} onClick={() => {navigate(item?.to)}} style={index == item.index ? cssListActive() : {}}>
                <ListItemIcon style={index == item.index ? cssIconActive() : {}}>
                    {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            ) : (
              <ListItemButton key={i} onClick={() => {}} style={{}}>
                <ListItemIcon style={index == item.index ? cssIconActive() : {}}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label} />
                {isMobile() ? (
                  <div style={{padding: '10px'}}>
                    <Badge color="primary" badgeContent={"PRO"} showZero/>
                  </div>
                ) : (
                  open ? (
                    <div style={{padding: '10px'}}>
                      <Badge color="primary" badgeContent={"PRO"} showZero/>
                    </div>
                  ) : (null)
                )}
              </ListItemButton>
            )
        ))}
    </React.Fragment>
);
  
export const secondaryListItems = (index, onLogout, navigate) => (
    <React.Fragment>
      {submenuItems?.map((item, i) => (
        <ListItemButton onClick={() => {navigate(item?.to)}} style={index == item.index ? cssListActive() : {}}>
          <ListItemIcon>
            {item?.icon}
          </ListItemIcon>
          <ListItemText primary={item?.label} />
        </ListItemButton>
      ))}
      <ListItemButton onClick={onLogout}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Sair" />
      </ListItemButton>
    </React.Fragment>
);

const DrawerDesktop = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      '& .MuiDrawer-paper': {
        position: 'fixed',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: theme.spacing(7),
          [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
          },
        }),
      },
    }),
);

const DrawerMobile = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    background: "#fff",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth+15}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);


export default ({open, setOpen, toggleDrawer, menuIndex}) => {

    const {user, logout} = useContext(MainContext);

    const navigate = useNavigate();

    const handleLogout = () => {
      logout();
      navigate("/login")
    }

    return (
        <>
        {isMobile() ? (
          <DrawerMobile variant="permanent" open={open} style={{zIndex: '99999999', paddingLeft: '23px'}}>
            <Toolbar
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
              }}
            >
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
              {mainListItems(menuIndex, navigate, user?.modulos, open)}
              <Divider sx={{ my: 1 }} />
              {secondaryListItems(menuIndex, handleLogout, navigate)}
            </List>
          </DrawerMobile>
        ) : (
          <DrawerDesktop variant="permanent" open={open}>
            <Toolbar
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
              }}
            >
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <img src={user?.logo_pequena} width={40} style={{marginRight: '130px'}}/>
                <IconButton onClick={toggleDrawer}>
                  <ChevronLeftIcon />
                </IconButton>
              </div>
            </Toolbar>
            <Divider />
            <List component="nav">
              {mainListItems(menuIndex, navigate, user?.modulos, open)}
              <Divider sx={{ my: 1 }} />
              {secondaryListItems(menuIndex, handleLogout, navigate)}
            </List>
          </DrawerDesktop>
        )}
      </>
    )
}
