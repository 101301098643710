import React from 'react';
import "./style.css";
import { useNavigate } from 'react-router-dom';

export default ({cardappyo, franquia, empresa, goBack, title}) => {

  const navigate = useNavigate();

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: empresa?.nome,
        text: empresa?.descricao,
        url: window?.location?.origin+"/"+localStorage.getItem("CARDAPPYO_CURRENT_FILIAL")+"/"+localStorage.getItem("CARDAPPYO_CURRENT_KEYBIND")+(localStorage.getItem("CARDAPPYO_CURRENT_MESA") != null ? ("/"+localStorage.getItem("CARDAPPYO_CURRENT_MESA")) : ""),
      })
      .then(() => console.log('Sucesso ao compartilhar'))
      .catch((error) => console.log('Erro ao compartilhar', error));
    } else {
      console.log('Seu navegador nao suporta o modo de compartilhamento.');
    }
  }

  const handleGoBack = () => {
    navigate(-1);
  }

  return (
    (empresa && cardappyo) ? (
      <div style={{display: 'flex', zIndex: '9999', justifyContent: 'space-between', alignItems: 'center', paddingTop: "10px", paddingBottom: '10px', position: 'sticky', top: 0, width: '100%', background: "#fff", borderBottom: 'solid 1px #f7f7f7', boxShadow: '0 1px 4px rgba(0, 0, 0, 0.1)'}}>
          <div style={{marginLeft: '10px', display: 'flex', alignItems: 'center'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              {goBack ? (
                <div style={{paddingTop: '11px', paddingBottom: '11px', display: 'flex', alignItems: 'center'}}>
                  <i class="fa fa-chevron-left" style={{fontSize: '14pt'}} onClick={handleGoBack}></i>
                </div>
              ) : (
                <img src={empresa?.logo_pequena} style={{width: '40px', height: '40px', objectFit: 'cover', borderRadius: '50%'}}/>
              )}
            </div>&nbsp;&nbsp;
            <div style={{marginRight: '10px'}}>
              {title ? (
                <h4>{title}</h4>
              ) : (
                <h4>{franquia?.nome_fantasia?.length >= 25 ? franquia?.nome_fantasia?.substring(0, 25)+"..." : franquia?.nome_fantasia}</h4>
              )}
            </div>
          </div>
          {goBack ? (null) : (
            <div style={{marginRight: '10px'}}>
              <i class="fa fa-share" onClick={handleShare} style={{padding: '10px', border: 'solid #f5f5f5 1px', borderRadius: '50%', cursor: "pointer"}}></i>
            </div>
          )}
      </div>
    ):(null)
  )
}


