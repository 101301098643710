import * as React from 'react';

import "./style.css";
import AdminMainContainer from '../../components/AdminMainContainer';
import { AdminNotificationManager, Hr } from '../../components';
import { Alert, AlertTitle, Button, Container, TextField, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Api from '../../Api';
import { MainContext } from '../../helpers/MainContext';
import isMobile from 'is-mobile';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import QRCode from "react-qr-code";
import DownloadIcon from '@mui/icons-material/Download';
import AdminAcordionItensDetalhes from '../../components/AdminAcordionItensDetalhes';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import PixIcon from '@mui/icons-material/Pix';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CurrencyInput from '../../components/CurrencyInput';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import Utils from '../../Utils';
import html2pdf from 'html2pdf.js';
import QRZin from 'qrcode-generator';

export default () => {

  const {user} = React.useContext(MainContext);

  const [mesas, setMesas] = React.useState([]);
  const [pessoas, setPessoas] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openModalFechaConta, setOpenModalFechaConta] = React.useState(false);
  const [openModalCriarMesa, setOpenModalCriarMesa] = React.useState(false);
  const [mesaCreateMode, setMesaCreateMode] = React.useState('uma');
  const [mesaCount, setMesaCount] = React.useState(0);
  const [selectedMesa, setSelectedMesa] = React.useState(null);
  const [pedidosAbertos, setPedidosAbertos] = React.useState([]);
  const [itens, setItens] = React.useState([]);
  const [itensToRender, setItensToRender] = React.useState([]);
  const [methodPayment, setMethodPayment] = React.useState('dinheiro');
  const [desconto, setDesconto] = React.useState("R$ 0");
  const [troco, setTroco] = React.useState("R$ 0");
  const [subtotalMesa, setSubtotalMesa] = React.useState(0);
  const [taxaMesa, setTaxaMesa] = React.useState(0);

  React.useEffect(()=>{

    const fetchPedidosAbertos = async () => {
      let responsePedidos = await Api.admin.getPedidosAbertos({id_empresa: user?.id_empresa});
      setPedidosAbertos(responsePedidos?.data?.data);
      let ids_pedidos = [];
      for(let i = 0; i < responsePedidos?.data?.data?.length; i++){
          let pedido = responsePedidos?.data?.data[i];
          ids_pedidos.push(pedido?.id_pedido);
      }
      let responseItens = await Api.admin.getPedidoItens({ids_pedidos: ids_pedidos});
      setItens(responseItens?.data?.data);
    }

    fetchMesas();
    fetchPedidosAbertos();

    const loop = () => {
      fetchMesas();
      fetchPedidosAbertos();
    }

    const intervalId = setInterval(loop, 3000);
    return () => clearInterval(intervalId);

  }, [])

  const fetchMesas = async () => {
    let responseMesas = await Api.admin.getMesas({id_cardapio: user?.id_cardapio});
    setMesas(responseMesas?.data?.data);
    let ids_mesas = [];
    for(let i = 0; i < responseMesas?.data?.data?.length; i++){
      let mesa = responseMesas?.data?.data[i];
      ids_mesas?.push(mesa?.id_mesa);
    }
    let responsePessoas = await Api.admin.getPessoas({ids_mesas: ids_mesas})
    setPessoas(responsePessoas?.data?.data);
  }

  const generateQRCode = (text, size = 4, errorCorrection = 'M') => {
    const qr = QRZin(0, errorCorrection);
    qr.addData(text);
    qr.make();
    const base64QRCode = qr.createDataURL(size, 0);
    return base64QRCode;
  };

  const printDocumentOneMesa = (mesa, type) => {

    switch(type){
      case "qrcode":
        let beginHTML = `<div style="display: grid;  grid-template-columns: 230px 230px 230px; gap: 4px; justify-content: center;">`;
        let endHTML = `</div>`;

        let mesaDiv = `
          <div class='qr-code-mesa' style="border: solid 2px #000; padding: 10px; margin: 2px; border-radius: 4px; margin-bottom: 15.5px;">
            <div style="background: #000000; color: white; margin-bottom: 5px; padding: 8px; text-align: center;">
              <b style='font-size: 16pt;'>${mesa?.nome}</b>
            </div>
            <div style="display: flex; align-items: center; justify-content: center;">
              <img src="${generateQRCode(getSpecificMesaUrl(mesa))}" style="height: auto; width: 220px;" />
            </div>
            <div style="background: #000000; color: white; margin-top: 5px; padding: 8px; text-align: center;">
              <b style='font-size: 14pt;'>Faça seu pedido!</b>
            </div>
          </div>
        `;
        
        const content = mesaDiv;

        const options = {
          margin: 10,
          filename: mesa?.nome+'-qrcode-.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };

        html2pdf().from(beginHTML+content+endHTML).set(options).save();

        break;
      case "image":
        var newImg = document.createElement('img');

        newImg.src = generateQRCode(getSpecificMesaUrl(mesa), 7);

        newImg.width = 250; 
        newImg.height = 250;

        newImg.onload = function() {

          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext('2d');

          canvas.width = newImg.width;
          canvas.height = newImg.height;

          ctx.drawImage(newImg, 0, 0);
          
          var link = document.createElement('a');
          link.download = mesa?.nome+'.png'; 
          link.href = canvas.toDataURL('image/png');
          link.click();
        };
      break;
    }
    
  };

  const printDocument = () => {
    
    const mesasDivs = [];
    
    let beginHTML = `<div style="display: grid;  grid-template-columns: 230px 230px 230px; gap: 4px; justify-content: center;">`;
    let endHTML = `</div>`;

    let count = 0;

    mesas?.forEach((m, index) => {
      let mesaDiv = `
        <div class='qr-code-mesa' style="border: solid 2px #000; padding: 10px; margin: 2px; border-radius: 4px; margin-bottom: 15.5px;">
          <div style="background: #000000; color: white; margin-bottom: 5px; padding: 8px; text-align: center;">
            <b style='font-size: 16pt;'>${m?.nome}</b>
          </div>
          <div style="display: flex; align-items: center; justify-content: center;">
            <img src="${generateQRCode(getSpecificMesaUrl(m))}" style="height: auto; width: 220px;" />
          </div>
          <div style="background: #000000; color: white; margin-top: 5px; padding: 8px; text-align: center;">
            <b style='font-size: 14pt;'>Faça seu pedido!</b>
          </div>
        </div>
      `;
      mesasDivs.push(mesaDiv);
    });

    const content = mesasDivs.join('');

    const options = {
      margin: 10,
      filename: 'mesas-qrcode.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    html2pdf().from(beginHTML+content+endHTML).set(options).save();
  };

  const handleChangePaymentMethod = (event, newAlignment) => {
    setMethodPayment(newAlignment);
  };

  const handleChangeMesaCreateMode = (event, newAlignment) => {
    setMesaCreateMode(newAlignment);
  };

  const getMesaIsOcuped = (mesa) => {
    return pessoas?.filter(p => {return p.id_mesa == mesa?.id_mesa})?.length > 0;
  }

  const handleClickOpen = (mesa) => {
    setSelectedMesa(mesa);
    let pedidos = pedidosAbertos?.filter(p => {return p.id_mesa == mesa?.id_mesa});
    let its = [];
    for(let i = 0; i < pedidos.length; i++){
      let _its = itens.filter(it => {return it?.id_pedido == pedidos[i]?.id_pedido});
      for(let j = 0; j < _its.length; j++){
        its.push(_its[j]);
      }
    }
    setItensToRender(its);

    let subtotal = 0
    for(let i = 0; i < pedidos.length; i++){
      subtotal += Number(pedidos[i]?.subtotal);
    }
    let tx = (subtotal / 100) * user?.taxa_garcom;

    setSubtotalMesa(subtotal);
    setTaxaMesa(tx);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getMesaUrl = () => {
    return window.origin+"/"+user?.id_filial+"/"+user?.keybind+"/"+selectedMesa?.id_mesa
  }

  const getSpecificMesaUrl = (mesa) => {
    return window.origin+"/"+user?.id_filial+"/"+user?.keybind+"/"+mesa?.id_mesa
  }

  const handleClickFechaConta = () => {
    setOpenModalFechaConta(true);
  }

  const handleCloseModalFechaConta = () => {
    setOpenModalFechaConta(false);
    setDesconto("R$ 0");
    setTroco("R$ 0");
    setMethodPayment("dinheiro");
  }

  const handleClickCriarMesa = () => {
    setOpenModalCriarMesa(true);
  }

  const handleCloseCriarMesa = () => {
    setOpenModalCriarMesa(false);
  }

  const handleInputChangeMesaCount = (event) => {
    const value = event.target.value;
    if (value.length <= 3) { 
      setMesaCount(value);
    }
  };

  const handleClickCriarMesaOK = async () => {

    switch(mesaCreateMode){
      case "uma":
        let responseCreateUma = await Api.admin.createMesa({id_cardapio: user?.id_cardapio, qtd: 1});
        Utils.notify("success", responseCreateUma?.data?.message);
        break;
      case "varias":
        if(mesaCount <= 0){
          Utils.notify("error", "O número de mesas deve ser superior a zero.")
          return;
        }
        if(mesaCount > 500){
          Utils.notify("error", "O número de mesas deve ser inferior a 501.")
          return;
        }
        let responseCreateVarias = await Api.admin.createMesa({id_cardapio: user?.id_cardapio, qtd: mesaCount});
        Utils.notify("success", responseCreateVarias?.data?.message);
        break;
    }

    fetchMesas();

    setOpenModalCriarMesa(false);

  }

  const handleModalFechaContaOk = async () => {

    let id_mesa = selectedMesa?.id_mesa;
    let total = (subtotalMesa+taxaMesa)-Utils.formatCurrencyToNumber(""+desconto);
    let _troco = Utils.formatCurrencyToNumber(""+troco);

    if(methodPayment == "dinheiro"){
      if(_troco > 0 && _troco < total){
        Utils.notify("error", "o troco é menor que o total da conta")
        return;
      }
    }

    let ids_pedidos = [];
    for(let i = 0; i < pedidosAbertos.length; i++){
        let pedido = pedidosAbertos[i];
        ids_pedidos.push(pedido?.id_pedido);
    }

    let response = await Api.admin.fecharConta({id_mesa: id_mesa, ids_pedidos: ids_pedidos, itens: itens, subtotal: subtotalMesa, taxa: taxaMesa, total: total, troco: _troco});
    Utils.notify("success", response?.data?.message);

    handleCloseModalFechaConta();
    setOpen(false);

  }

  const getTrocoFormated = () => {
    let r = Number(Utils.formatCurrencyToNumber(""+troco));
    return r;
  }

  const getDescontoFormated = () => {
    let d = Number(Utils.formatCurrencyToNumber(""+desconto));
    return d;
  }

  const getTroco = () => {
    let d = getDescontoFormated();
    let r = (getTrocoFormated() - ((subtotalMesa + taxaMesa) - d));
    return r;
  }

  return (
      <AdminMainContainer menuIndex={4}>
        <AdminNotificationManager callback={() => {}}/>
        <Dialog
          open={openModalCriarMesa}
          onClose={handleCloseCriarMesa}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`Criar Mesas`}
          </DialogTitle>
          <DialogContent style={{paddingBottom: '0px'}}>
            <div>
              <ToggleButtonGroup
                color="primary"
                value={mesaCreateMode}
                exclusive
                onChange={handleChangeMesaCreateMode}
                aria-label="Platform"
              >
                <ToggleButton value="uma"><TableRestaurantIcon/>&nbsp;Criar Uma</ToggleButton>
                <ToggleButton value="varias"><TableRestaurantIcon/>&nbsp;Criar Várias</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div style={{marginTop: '10px'}}>
              {mesaCreateMode == "varias" ? (
                <TextField id="outlined-basic" inputProps={{ maxLength: 3 }} value={mesaCount} onChange={handleInputChangeMesaCount} style={{width: '100%'}} type='number' label="Quantas mesas?" variant="outlined" />
              ) : (null)}
            </div>
          </DialogContent>
          <DialogActions style={{padding: '24px'}}>
            <Button onClick={handleCloseCriarMesa}>Cancelar</Button>
            <Button onClick={handleClickCriarMesaOK} variant='contained' autoFocus>
              Criar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openModalFechaConta}
          onClose={handleCloseModalFechaConta}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`Fechar conta da ${selectedMesa?.nome}`}
          </DialogTitle>
          <DialogContent>
            <div>
              <ToggleButtonGroup
                color="primary"
                value={methodPayment}
                exclusive
                onChange={handleChangePaymentMethod}
                aria-label="Platform"
              >
                <ToggleButton size={isMobile() ? 'small' : 'medium'} value="dinheiro"> {isMobile() ? (<>Dinheiro</>) : (<><AttachMoneyIcon/>&nbsp;Dinheiro</>)}</ToggleButton> 
                <ToggleButton size={isMobile() ? 'small' : 'medium'} value="pix"> {isMobile() ? (<>Pix</>) : (<><PixIcon/>&nbsp;Pix</>)}</ToggleButton> 
                <ToggleButton size={isMobile() ? 'small' : 'medium'} value="cartao"> {isMobile() ? (<>Cartão</>) : (<><CreditCardIcon/>&nbsp;Cartão</>)}</ToggleButton> 
              </ToggleButtonGroup>
            </div>
            <div style={{marginTop: '10px'}}>
              <b>Algum desconto?</b>
              <CurrencyInput style={{width: '100%', marginTop: '5px'}} value={desconto} onChange={(e) => {setDesconto(e.target.value)}}/>
            </div>
            {methodPayment == "dinheiro" ? (
              <div style={{marginTop: '10px'}}>
                <b>Troco pra quanto?</b>
                <CurrencyInput style={{width: '100%', marginTop: '5px'}} value={troco} onChange={(e) => {setTroco(e.target.value)}}/>
              </div>
            ) : (null)}
            <Paper sx={{mt: 2}}>
              <div className='pedido-item-admin-totals'>
                  <div>Subtotal</div>
                  <div><b>{Number(subtotalMesa).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></div>
              </div>
              <div className='pedido-item-admin-totals'>
                  <div>Desconto</div>
                  <div><b>{desconto}</b></div>
              </div>
              <div className='pedido-item-admin-totals'>
                  <div>Taxa</div>
                  <div><b>{user?.taxa_garcom}%({Number(taxaMesa).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})})</b></div>
              </div>
              <div className='pedido-item-admin-totals'>
                  <div>Total</div>
                  <div><b>{Number((subtotalMesa+taxaMesa)-Utils.formatCurrencyToNumber(""+desconto)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></div>
              </div>
              {methodPayment == "dinheiro" ? (
                  <div className='pedido-item-admin-totals'>
                    <div>Troco</div>
                    <div><b style={{color: 'green'}}>{getTroco() > 0 ? getTroco().toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : ""}</b></div>
                </div>
              ) : (null)}
            </Paper>
            
          </DialogContent>
          <DialogActions style={{padding: '24px'}}>
            <Button onClick={handleCloseModalFechaConta}>Cancelar</Button>
            <Button onClick={handleModalFechaContaOk} variant='contained' autoFocus>
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`Detalhes da ${selectedMesa?.nome}`}
          </DialogTitle>
          <DialogContent>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div>
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100px", width: "100px" }}
                  value={getMesaUrl()}
                  viewBox={`0 0 256 256`}
                />
              </div>&nbsp;&nbsp;
              <div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Button variant='outlined' size='small' onClick={() => {printDocumentOneMesa(selectedMesa, "qrcode")}}><LocalPrintshopIcon/>{isMobile() ? ("") : ("Imprimir")}</Button>&nbsp;
                  <Button variant='outlined' size='small' onClick={() => {printDocumentOneMesa(selectedMesa, "image")}}><DownloadIcon/>{isMobile() ? ("") : ("Baixar")}</Button>
                </div>
                <p style={{fontSize: isMobile() ? '8pt' : '12pt', marginTop: '5px'}}>Escaneie o QR Code com o seu celular e faça seu pedido.</p>
              </div>
            </div>
            {itensToRender?.length > 0 ? (
              <div style={{marginTop: '10px'}}>
                <AdminAcordionItensDetalhes itensToRender={itensToRender}/>
              </div>
            ) : (
              getMesaIsOcuped(selectedMesa) ? (
                <Alert severity="warning" style={{marginTop: '10px'}}>
                  <AlertTitle>Atenção</AlertTitle>
                  Nenhum pedido lançado pelo cliente.
                </Alert>
              ) : (null)
            )}
          </DialogContent>
          <DialogActions style={{padding: '24px'}}>
            <Button onClick={handleClose}>Voltar</Button>
            {getMesaIsOcuped(selectedMesa) && itensToRender?.length > 0 ? (
              <Button onClick={() => {handleClickFechaConta()}} variant='contained' autoFocus>
                <PointOfSaleIcon/>&nbsp;Fechar Conta
              </Button>
            ) : (null)}
          </DialogActions>
        </Dialog>
        <Container maxWidth="xl" sx={{ mt: 3, mb: 2 }} style={{paddingLeft: isMobile() ? '24px' : '0px'}}>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: '25px'}}>
            <Typography variant='h6'>Mesas({mesas?.length})</Typography>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Button variant='outlined' size={isMobile() ? 'small' : 'medium'} onClick={printDocument} color='secondary'><LocalPrintshopIcon/> QR-codes</Button>&nbsp;
              <Button variant='outlined' size={isMobile() ? 'small' : 'medium'} onClick={handleClickCriarMesa}>+Mesas</Button>
            </div>
          </div>
          <div style={{ overflow: 'auto', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px'}}>
            {mesas.map((mesa, index) => (
              <div>
                <Paper
                  sx={{
                    height: 152,
                    width: 152,
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'dark' ? '#1A2027' : (getMesaIsOcuped(mesa)) ? '#fff1f1' : '#ffffff',
                  }}
                >
                  <center>
                    <div style={{padding: '8px', height: '152px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                      <div>
                        <div style={{marginBottom: '5px'}}>
                          <b>{mesa?.nome}</b>
                        </div>
                        <div style={{marginBottom: '5px'}}>
                          <Button fullWidth variant='outlined' size='small' color='secondary' onClick={() => {handleClickOpen(mesa)}}><RemoveRedEyeIcon/>&nbsp;Detalhes</Button>
                        </div>
                        <div style={{marginBottom: '5px'}}>
                          <Button fullWidth variant='contained' size='small' color='secondary'>+&nbsp;Pedido</Button>
                        </div>
                        <div>
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                              <lebel style={{border: getMesaIsOcuped(mesa) ? 'solid 1px red' : 'solid 1px green', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                &nbsp;&nbsp;<div className={`dot ${getMesaIsOcuped(mesa) ? 'dot-danger' : 'dot-success'}`}></div>&nbsp;
                                <div>{getMesaIsOcuped(mesa) ? "ocupada" : "livre"}</div>&nbsp;&nbsp;
                              </lebel>
                          </div>
                        </div>
                      </div>
                    </div>
                  </center>
                </Paper>
              </div>
            ))}
            <Hr/>
          </div>
          {isMobile() ? (
            <><br/><br/><br/><br/></>
          ) : (null)}
        </Container>
      </AdminMainContainer>
  );
}