import { useState, useEffect } from 'react';

const getAddressesFromLocalStorage = () => {
  const addressList = localStorage.getItem('CARDAPPYO_ADDRESS_LIST');
  return addressList ? JSON.parse(addressList) : [];
};

const useAddress = () => {

  const [addressList, setAddressList] = useState(getAddressesFromLocalStorage());

  const addAddress = (address) => {
    let addresses = getAddressesFromLocalStorage();
    const updatedAddressList = [...addresses, address];
    setAddressList(updatedAddressList);
    localStorage.setItem('CARDAPPYO_ADDRESS_LIST', JSON.stringify(updatedAddressList));
  };

  const removeAddress = (id) => {
    let addresses = getAddressesFromLocalStorage();
    const updatedAddressList = addresses.filter((a) => a.id !== id);
    setAddressList(updatedAddressList);
    localStorage.setItem('CARDAPPYO_ADDRESS_LIST', JSON.stringify(updatedAddressList));
  };


  useEffect(() => {
    const storedAddressList = getAddressesFromLocalStorage();
    setAddressList(storedAddressList);
  }, []);

  return {
    addressList,
    addAddress,
    removeAddress,
    getAddressesFromLocalStorage
  };

};

export default useAddress;