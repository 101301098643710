import React, { useEffect, useState } from 'react';
import "./style.css";
import {BottomNavigation, Header} from '../../components';
import Api from '../../Api';
import Utils from '../../Utils';

export default () => {

  const [cardappyo, setCardappyo] = useState(null);
  const [empresa, setEmpresa] = useState(null);
  const [franquia, setFranquia] = useState(null);
  const [pessoas, setPessoas] = useState(null);

  useEffect(() => {
    const fetchCardappyo = async () => {
      let responseCardappyo = await Api.getCardappyo({keybind: localStorage.getItem("CARDAPPYO_CURRENT_KEYBIND")});
      setCardappyo(responseCardappyo?.data?.data);
      let responseEmpresa = await Api.getEmpresa({id_empresa: responseCardappyo?.data?.data?.id_empresa});
      setEmpresa(responseEmpresa?.data?.data);
      let responseFilial = await Api.getFilial({id_empresa: responseCardappyo?.data?.data?.id_empresa, id_filial: localStorage.getItem("CARDAPPYO_CURRENT_FILIAL")});
      setFranquia(responseFilial?.data?.data);
      let responsePessoas = await Api.getPessoaMesa({id_mesa: localStorage.getItem("CARDAPPYO_CURRENT_MESA")})
      setPessoas(responsePessoas?.data?.data);
    }
    fetchCardappyo();
  }, [])

  return (
    <>
      <Header goBack={true} title={"Pessoas na Mesa"} cardappyo={cardappyo} franquia={franquia} empresa={empresa}/>
      <div style={{marginLeft: '16px', marginRight: '16px'}}>
        {pessoas?.length <= 0 ? (
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 'calc(100vh - 100px)'}}>
            <div>
              <h4 style={{color: '#4b4b4b', marginTop: '20px', marginBottom: '10px', textAlign: 'center'}}>Ningém na mesa até o momento.</h4>
            </div>
          </div>
        ) : (
          <>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px'}}>
              <h4 style={{color: '#4b4b4b'}}>Pessoas ({pessoas?.length})</h4>
            </div>
            {pessoas?.map((pessoa, index) => (
              <div key={index} className={"address"}>
                <i class="fa fa-user" style={{fontSize: '14pt'}}></i>
                &nbsp;&nbsp;<label style={{fontSize: '8pt', color: '#4b4b4b'}}>{pessoa?.nome}</label>&nbsp;-&nbsp;
                <label style={{fontSize: '8pt', color: '#4b4b4b'}}>{Utils.formatDate(pessoa?.ts_registro)}</label>&nbsp;&nbsp;
              </div>
            ))}
            <br/>
            <br/>
            <br/>
          </>
        )}
      </div>
      <BottomNavigation activeColor={empresa?.cor_primaria} activeTab={1} />
    </>
  )
}