import React, { useContext, useEffect, useMemo, useState } from 'react';
import "./style.css";
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import SearchIcon from '@mui/icons-material/Search';
import isMobile from 'is-mobile';
import { Button, ButtonGroup, Card, CardActions, CardContent, Dialog, FormControl, InputAdornment, InputLabel, MenuItem, Select, Tabs, TextField, Tooltip } from '@mui/material';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import StoreIcon from '@mui/icons-material/Store';
import AdminCardPedido from '../../components/AdminCardPedido';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TuneIcon from '@mui/icons-material/Tune';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Utils from '../../Utils';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
export default ({pedidos, itens, fetchPedidos}) => {

    const [filter, setFilter] = useState(0);
    const [filterNumberPedido, setFilterNumberPedido] = useState("");
    const [filterNamePedido, setFilterNamePedido] = useState("");
    const [modalFilterMobileOpen, setModalFilterMobileOpen] = useState(false);
    const [tab, setTab] = useState(1);

    const handleFilterChange = (index) => {
        setFilter(index)
    }

    const getCssFilter = (index) => {
        if(index == filter){
            return {background: Utils.getThemeDefault()?.palette?.primary?.main, color: "white"};
        }else{
            return {};
        }
    }

    const getCssButton = () => {
        return {background: Utils.getThemeDefault()?.palette?.primary?.main, color: "white"};
    }

    const clearFilter = () => {
        setFilter(0);
        setFilterNumberPedido("");
        setFilterNamePedido("");
    }

    const getPedidosMapperView = () => {
        switch (filter) {
            case 0:
                return (filterNumberPedido || filterNamePedido) ? pedidos.filter(p => p.id_ref.includes(filterNumberPedido) && p.nome.includes(filterNamePedido)) : pedidos;
            case 1:
                return (filterNumberPedido || filterNamePedido) ? pedidos?.filter(p => p.tipo === "Entrega" && p.id_ref.includes(filterNumberPedido) && p.nome.includes(filterNamePedido)) : pedidos?.filter(p => p.tipo === "Entrega");
            case 2:
                return (filterNumberPedido || filterNamePedido) ? pedidos?.filter(p => p.tipo === "Retirada" && p.id_ref.includes(filterNumberPedido) && p.nome.includes(filterNamePedido)) : pedidos?.filter(p => p.tipo === "Retirada");
            case 3:
                return (filterNumberPedido || filterNamePedido) ? pedidos?.filter(p => p.tipo === "Mesa" && p.id_ref.includes(filterNumberPedido) && p.nome.includes(filterNamePedido)) : pedidos?.filter(p => p.tipo === "Mesa");
            default:
                return pedidos;
        }
    };

    const handleTabChange = (value) => {
        setTab(value);
    };

    const handleModalFilterMobile = () => {
        setModalFilterMobileOpen(true);
    }

    const handleModalFilterMobileClose = () => {
        setModalFilterMobileOpen(false);
        clearFilter();
    }

    const handleApplyFilterMobile = () => {
        setModalFilterMobileOpen(false);
    }

    return (
    <>
        <Container maxWidth="xl" sx={{ mt: 2, mb: 2 }}>
            <Typography variant="h6" style={{ color: '#4b4b4b', fontWeight: 'bold', fontSize: '12pt', marginLeft: isMobile() ? "10px" : "0px" }}>
                Meus pedidos
            </Typography>
            {/*<Button onClick={playAudio}>
                Ativar Efeito Sonoro
            </Button>*/}
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <ButtonGroup sx={{mt: 1}} variant="outlined" aria-label="outlined button group" style={{marginLeft: isMobile() ? "10px" : "0px"}}>
                    <Button style={getCssFilter(0)} onClick={() => {handleFilterChange(0)}}>Todos</Button>
                    <Tooltip title="Delivery">
                        <Button style={getCssFilter(1)} onClick={() => {handleFilterChange(1)}}><DeliveryDiningIcon/>{isMobile() ? `` : `Delivery`}</Button>
                    </Tooltip>
                    <Tooltip title="Retirada">
                        <Button style={getCssFilter(2)} onClick={() => {handleFilterChange(2)}}><StoreIcon/>{isMobile() ? `` : `Retirada`}</Button>
                    </Tooltip>
                    <Tooltip title="Mesa">
                        <Button style={getCssFilter(3)} onClick={() => {handleFilterChange(3)}}><TableRestaurantIcon/>{isMobile() ? `` : `Mesa`}</Button>
                    </Tooltip>
                </ButtonGroup>
                {isMobile() ? (<></>) : (
                    <>
                        <div style={{marginLeft: '30px'}}>
                            <TextField
                                id="input-with-icon-textfield"
                                label="Nº Pedido"
                                type='number'
                                value={filterNumberPedido}
                                onChange={e => setFilterNumberPedido(e.target.value)}
                                InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                    <SearchIcon />
                                    </InputAdornment>
                                ),
                                }}
                                variant="standard"
                            />
                        </div>
                        <div style={{marginLeft: '30px'}}>
                            <TextField
                                id="input-with-icon-textfield"
                                label="Buscar pelo cliente"
                                value={filterNamePedido}
                                onChange={e => setFilterNamePedido(e.target.value)}
                                InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                    <SearchIcon />
                                    </InputAdornment>
                                ),
                                }}
                                variant="standard"
                            />
                        </div>
                        <Button style={{marginLeft: '15px', marginRight: '15px'}} color="primary" onClick={() => {clearFilter()}}><FilterAltOffIcon/>Limpar Filtro</Button>
                    </>
                )}
              </div>
              {isMobile() ? (<>
                
              </>) : (<>
                <Button style={getCssButton()}>+ Novo Pedido</Button>
              </>)}
            </div>
        </Container>
        <Container maxWidth="xl" sx={{ }} style={{paddingLeft: isMobile() ? '48px' : '48px'}}>

            <Grid container spacing={3} style={{ height: isMobile() ? 'calc(100vh - 340px)' : 'calc(100vh - 180px)' }}>
            
                {isMobile() ? (
                    <>
                        <Dialog
                            open={modalFilterMobileOpen}
                            onClose={handleModalFilterMobileClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                            {"Filtar Pedidos"}
                            </DialogTitle>
                            <DialogContent>
                                <div>
                                    <div style={{}}>
                                        <TextField
                                            id="input-with-icon-textfield"
                                            label="Nº Pedido"
                                            type='number'
                                            value={filterNumberPedido}
                                            onChange={e => setFilterNumberPedido(e.target.value)}
                                            variant="standard"
                                        />
                                    </div>
                                    <div style={{}}>
                                        <TextField
                                            id="input-with-icon-textfield"
                                            label="Buscar pelo cliente"
                                            value={filterNamePedido}
                                            onChange={e => setFilterNamePedido(e.target.value)}
                                            variant="standard"
                                        />
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleModalFilterMobileClose}>Limpar</Button>
                                <Button onClick={handleApplyFilterMobile} variant="contained" autoFocus>
                                    Aplicar
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between', width: '100%', marginTop: '30px', marginBottom: '20px'}}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Ver pedidos</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={tab}
                                    label="Ver pedidos"
                                    onChange={(e) => {handleTabChange(e.target.value)}}
                                >
                                    <MenuItem value={1}>Em análise</MenuItem>
                                    <MenuItem value={2}>Em produção</MenuItem>
                                    <MenuItem value={3}>Prontos</MenuItem>
                                </Select>
                            </FormControl>&nbsp;
                            <Button variant='outlined' style={{height: '55px'}} onClick={handleModalFilterMobile}>
                                <TuneIcon/>
                            </Button>
                        </div>

                        {filterNamePedido || filterNumberPedido ? (
                            <div style={{marginBottom: '10px', marginTop: '-10px', display: 'flex', justifyContent: 'flex-start', width: '100%'}}>
                                <b style={{color: '#0098fc', textDecoration: 'underline', padding: '6px'}} onClick={() => {clearFilter()}}>limpar filtros ({filterNamePedido && filterNumberPedido ? "2" : (filterNamePedido || filterNumberPedido) ? "1" : "0"})</b>
                            </div>
                        ) : (null)}

                        {tab == 1 ? (
                            <Grid item id='tab' style={{ backgroundColor: '#ffe0cd', height: '100%', overflowY: 'auto', padding: 0, width: '100%' }}>
                                <Typography variant="h6" sx={{color: 'white', background: "#b25722", padding: "6px", fontWeight: 'bold', fontSize: '12pt', position: 'sticky', top: 0, zIndex: '999'}}>
                                    Em análise
                                </Typography>
                                <div style={{padding: '8px'}}>
                                    {getPedidosMapperView()?.filter((item => {return item.status == "A"})).map((pedido, index) => (
                                        <AdminCardPedido fetchPedidos={fetchPedidos} key={index} pedido={pedido} itens={itens?.filter(it => {return it.id_pedido == pedido.id_pedido})} />
                                    ))}
                                </div>
                                <br/>
                            </Grid>
                        ) : (null)}
                        {tab == 2 ? (
                            <Grid item id='tab' style={{ backgroundColor: '#ffe7c4', height: '100%', overflowY: 'auto', padding: 0, width: '100%' }}>
                                <Typography variant="h6" sx={{zIndex: '9999', color: 'white', background: "#b27822", padding: "6px", fontWeight: 'bold', fontSize: '12pt', position: 'sticky', top: 0, zIndex: '999'}}>
                                    Em produção
                                </Typography>
                                <div style={{padding: '8px'}}>
                                    {getPedidosMapperView()?.filter((item => {return item.status == "C"})).map((pedido, index) => (
                                        <AdminCardPedido fetchPedidos={fetchPedidos} key={index} pedido={pedido} itens={itens?.filter(it => {return it.id_pedido == pedido.id_pedido})} />
                                    ))}
                                </div>
                                <br/>
                            </Grid>
                        ) : (null)}
                        {tab == 3 ? (
                            <Grid item id='tab' style={{ backgroundColor: '#e4f0e3', height: '100%', overflowY: 'auto', padding: 0, width: '100%' }}>
                                <Typography variant="h6" sx={{zIndex: '9999', color: 'white', background: "#3f7a39", padding: "6px", fontWeight: 'bold', fontSize: '12pt', position: 'sticky', top: 0, zIndex: '999'}}>
                                    Prontos para entrega
                                </Typography>
                                <div style={{padding: '8px'}}>
                                    {getPedidosMapperView()?.filter((item => {return item.status == "D"})).map((pedido, index) => (
                                        <AdminCardPedido fetchPedidos={fetchPedidos} key={index} pedido={pedido} itens={itens?.filter(it => {return it.id_pedido == pedido.id_pedido})} />
                                    ))}
                                </div>
                                <br/>
                            </Grid>
                        ) : (null)}

                    </>
                ) : (
                    <>
                        <Grid item xs={4} sx={{mt: 4}} style={{ backgroundColor: '#ffe0cd', height: 'calc(100% - 32px)', overflowY: 'auto', padding: 0 }}>
                            <Typography variant="h6" sx={{ color: 'white', background: "#b25722", padding: "6px", fontWeight: 'bold', fontSize: '12pt', position: 'sticky', top: 0, zIndex: '999'}}>
                            Em análise
                            </Typography>
                            <div style={{padding: '8px'}}>
                                {getPedidosMapperView()?.filter((item => {return item.status == "A"})).map((pedido, index) => (
                                    <AdminCardPedido fetchPedidos={fetchPedidos} key={index} pedido={pedido} itens={itens?.filter(it => {return it.id_pedido == pedido.id_pedido})} />
                                ))}
                            </div>
                        </Grid>
                        <Grid item xs={4} sx={{mt: 4}} style={{ backgroundColor: '#ffe7c4', height: 'calc(100% - 32px)', overflowY: 'auto', padding: 0 }}>
                            <Typography variant="h6" sx={{ color: 'white', background: "#b27822", padding: "6px", fontWeight: 'bold', fontSize: '12pt', position: 'sticky', top: 0, zIndex: '999'}}>
                            Em produção
                            </Typography>
                            <div style={{padding: '8px'}}>
                                {getPedidosMapperView()?.filter((item => {return item.status == "C"})).map((pedido, index) => (
                                    <AdminCardPedido fetchPedidos={fetchPedidos} key={index} pedido={pedido} itens={itens?.filter(it => {return it.id_pedido == pedido.id_pedido})} />
                                ))}
                            </div>
                        </Grid>
                        <Grid item xs={4} sx={{mt: 4}} style={{ backgroundColor: '#e4f0e3', height: 'calc(100% - 32px)', overflowY: 'auto', padding: 0 }}>
                            <Typography variant="h6" sx={{ color: 'white', background: "#3f7a39", padding: "6px", fontWeight: 'bold', fontSize: '12pt', position: 'sticky', top: 0, zIndex: '999'}}>
                            Prontos para entrega
                            </Typography>
                            <div style={{padding: '8px'}}>
                                {getPedidosMapperView()?.filter((item => {return item.status == "D"})).map((pedido, index) => (
                                    <AdminCardPedido fetchPedidos={fetchPedidos} key={index} pedido={pedido} itens={itens?.filter(it => {return it.id_pedido == pedido.id_pedido})} />
                                ))}
                            </div>
                        </Grid>
                    </>
                )}
            </Grid>
        </Container>
    </>
  )
}
